export default function LogoLevel(){
    return(
        <a href="https://www.levelgroup.com.br/" target="_blank" rel="noreferrer" ><img
                              src="/logo-level.png"
                              width="75"
                              
                              alt="Next Level Acadamy"
                              placeholder="empty"
                              className="mt-1 mx-5" id="topoQuestao" /></a>
        );
}