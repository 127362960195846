import { IQuestionario } from "../entidades/questionario";

export default function Questao({ questao, ancora }:
    { questao: IQuestionario, ancora: string }) {

    return (<>
        <h2>
            <span>{questao.competencia}&nbsp;</span>
            <a href={ancora}
                className="btn btn-default btn-sm btn-up" style={{ borderRadius: "50%" }}><i className="fa fa-arrow-up"></i></a>
        </h2>
        <p className="lead">{questao.questao}</p>
    </>)
}