

import { FormEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { TipagemTelaLoginCadastro } from "../../tipos/tipos";
import TelaLoginCadastro from "../../components/TelaLoginCadastro";
// import "./index.css";

export const Login = () => {
  const auth = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [senha, setPassword] = useState('');
  const [msgErro, configurarErro] = useState('');

  useEffect(() => {
    // document.body.style.backgroundColor = "#f7f7f7";
    // document.body.style.backgroundImage = 'url(/logo-imagem-esquerda.png)';
    // document.body.style.backgroundPosition='0px 0px';
    // document.body.style.backgroundSize='550px';
    // document.body.style.backgroundRepeat='no-repeat';
    //document.body.style.backgroundSize='cover';

  }, [])

  const handleSubmit = async (e: FormEvent) => {

    e.preventDefault();
    configurarErro("");

    try {

      if (email && senha) {

        const retornoUsuario = await auth.signin(email, senha);
        const usuarioAvaliador = retornoUsuario.usuario?.flagAvaliador;
        const usuarioPadraoProcurementAssessment = retornoUsuario.usuario?.flagComprasAssessment && !retornoUsuario.usuario?.flagAvaliador;

        if (!retornoUsuario.erro && usuarioPadraoProcurementAssessment) {
          document.location.href = "/home";
          return;
        }

        if (!retornoUsuario.erro && usuarioAvaliador) {
          document.location.href = "/avaliador";
          return;
        }

        throw new Error(retornoUsuario.mensagem);
      }

    } catch (erro) {
      configurarErro((erro as Error).message);
    }
  }

  let placeHolderSenha: string = "Digite sua senha";
  let tituloTela = "Bem vindo(a)!";
  let subtitulo = "Use o formulário abaixo para acessar a conta.";
  const parametroGeraisTelaLogin: TipagemTelaLoginCadastro = {
    class: "login",
    tituloTela,
    subtitulo,
    handleSubmit,
    email,
    senha,
    placeHolderSenha,
    setEmail,
    setPassword
  }

  return (
    <>

      {/* <Header /> */}

      <TelaLoginCadastro parametrosGerais={parametroGeraisTelaLogin}>
        <div className="button-wrapper mb-4">
          <Link to={"/esqueciminhasenha?email=" + email} className='hover:underline' style={{ textDecoration: "none" }}> Perdeu sua senha? </Link>
          <button
            className="btn btn-primary btn-lg"
            type="submit">
            Entrar
          </button>
        </div>
        {msgErro.length > 0 && <div className="mt-2 mb-1 text-[#f00] font-bold w-[450px]">{msgErro}</div>}
        <div className="mb-2 mt-2">
          <label className="block text-base font-sans text-left ">
            Ainda não possui conta? <Link to="/cadastro" className='hover:underline' style={{ textDecoration: "none" }}> Cadastre-se! </Link>
          </label>
        </div>

      </TelaLoginCadastro>

    </>
  );
}