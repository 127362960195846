import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { IQuestionario } from "../entidades/questionario";
import QuestionarioDropDownTextAreaAvaliador from "./QuestionarioDropDownTextAreaAvaliador";
import React, { ChangeEvent, useState } from "react";
import { GravarAlterarRespostaAvaliador } from "../servicos/questionario";

interface ITabQuestionario {
    tabs: {
        SoftSkill: IQuestionario[] | undefined,
        HardSkill: IQuestionario[] | undefined,
        Funcional: IQuestionario[] | undefined,
        Conclusao: string
    },
    desativarForm?:boolean
}

export default function TabQuestionarioAvaliador({ tabs , desativarForm }: ITabQuestionario) {

    const EscolherNivel = async (valor: ChangeEvent<HTMLSelectElement>): Promise<void> => {
        const nivelEscolhido = Number(valor.target.value);
        const idResposta = Number(valor.target.id);
        await GravarAlterarRespostaAvaliador(idResposta, nivelEscolhido);
    }

    const GravarTextoTextArea = async (valor: React.FocusEvent<HTMLTextAreaElement>): Promise<void> => {
        const comentario = valor.target.value;
        const idResposta = Number(valor.target.id);
        await GravarAlterarRespostaAvaliador(idResposta, 0, comentario);
    }

    const retornoTabs = (indice: number, questionario: IQuestionario[]) => {
        return [{
            chaveAba: Object.keys(tabs)[indice],
            tituloAba: Object.keys(tabs)[indice] + ` (${questionario.length})`,
            questionario: questionario
        }];
    }

    const loopTabs = [
        ...retornoTabs(0, tabs.SoftSkill!),
        ...retornoTabs(1, tabs.HardSkill!),
        ...retornoTabs(2, tabs.Funcional!)
    ];

    return (
        <>
            <Row className='mt-5'>
                <Col>
                    <Tabs defaultActiveKey={Object.keys(tabs)[0]} id="tabsCompetencia" className='mb-3 navegar'>

                        {loopTabs.map((tab, indice) =>
                            <Tab key={indice} eventKey={tab.chaveAba} title={tab.tituloAba}>
                                <QuestionarioDropDownTextAreaAvaliador
                                    lista={tab.questionario}
                                    key={tab.chaveAba}
                                    EscolherNivel={EscolherNivel}
                                    GravarTextarea={GravarTextoTextArea}
                                    tipoLista={tab.chaveAba}
                                    desativarForm = {desativarForm}
                                />
                                <Row style={{ height: 200 }} />
                            </Tab>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </>
    );
}