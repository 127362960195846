import { IDashBoardInicial } from "../entidades/dashboardinicial";
import { IUsuario } from "../entidades/retorno-api";
import { IFiltroAvaliador } from "./interfaces/avaliadorservico";

const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
const token = `Bearer ${localStorage.getItem('authToken')}`;

export async function ObterUsuariosVinculados() : Promise<Array<IUsuario>> {
    
    const apiUsuariosVinculados = await fetch(`${process.env.REACT_APP_API!}/avaliador/usuarios-vinculados?emailAvaliador=${usuario.email}`,
    {
        method:"GET",
        headers: { "Authorization" : token! }
    });
   
    if(apiUsuariosVinculados.status !== 200)
        throw new Error("Erro");

    const retornoAPI = await apiUsuariosVinculados.json() as Array<IUsuario>;
    return retornoAPI;
}

export async function ObterUsuariosPorAvaliadorFiltro(filtro:IFiltroAvaliador) : Promise<Array<IUsuario>> {

    const apiUsuariosVinculados = await fetch(`${process.env.REACT_APP_API!}/avaliador/filtrar-usuarios-vinculados`,
    {
        method:"POST",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
        body : JSON.stringify({emailAvaliador : usuario.email , nomeEmail : filtro.nomeEmail, status: filtro.status})
    });
   
    if(apiUsuariosVinculados.status !== 200)
        throw new Error("Erro");

    const retornoAPI = await apiUsuariosVinculados.json() as Array<IUsuario>;
    return retornoAPI;
}


export async function CarregarDashBoardInicial() : Promise<Array<IDashBoardInicial>> {
    
    const apiUsuariosVinculados = await fetch(`${process.env.REACT_APP_API!}/avaliador/dashboard-inicial?emailAvaliador=${usuario.email}`,
    {
        method:"GET",
        headers: { "Authorization" : token! }
    });
   
    if(apiUsuariosVinculados.status !== 200)
        throw new Error("Erro");

    const retornoAPI = await apiUsuariosVinculados.json() as Array<IDashBoardInicial>;
    return retornoAPI;
}