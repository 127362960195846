import "./index.css"

import { Button, Col, Container, ProgressBar, Row, Table } from "react-bootstrap";
import GraficoResultado from "../../components/GraficoResultado";
import HeaderSistema from "../../components/HeaderSistema";
import { useEffect, useRef, useState } from "react";
import { IUsuario } from "../../entidades/retorno-api";
import { IQuestionario } from "../../entidades/questionario";
import { ObterRespostaProcurementAssessment } from "../../servicos/respostas";
import RelatorioFinal from "../../components/RelatorioFinal";
import TreinamentoSugestivo from "../../components/TreinamentoSugestivo";


export default function RelatorioFinalUsuario() {
    const [htmlGrafico, configurarGrafico] = useState<string>('');
    const [treinamentos, setTreinamento] = useState<Array<string>>([]);
    const paginaInicio = useRef<string>('');
    const [questionario, setQuestionario] = useState<Array<IQuestionario>>([]);
    const usuarioNomeEmail = useRef<{ nomeUsuario: string, emailUsuario: string }>();
    const [nomeEmailAvaliador, setNomeEmailAvaliador] = useState<{ nomeUsuario: string, emailUsuario: string }>();
    const [tabs, setTabs] = useState<{
        SoftSkill: Array<IQuestionario>,
        HardSkill: Array<IQuestionario>,
        Funcional: Array<IQuestionario>
    }>();

    const [usuario, setUsuario] = useState<IUsuario>(null!);

    useEffect(() => {

        const usuarioPadrao = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
        setUsuario(usuarioPadrao);

        usuarioNomeEmail.current = { nomeUsuario: usuarioPadrao.nome!, emailUsuario: usuarioPadrao.email! };

        localStorage.setItem("usuarioquestionario", JSON.stringify({ nomeUsuario: usuarioPadrao.nome!, emailUsuario: usuarioPadrao.email! }));


        if (usuarioPadrao.emailAvaliador !== '') {

            const CarregarAvaliador = async () => {

                const apiAvaliador = await fetch(`${process.env.REACT_APP_API!}/usuario/carregar-usuario-avaliador?emailAvaliador=${usuarioPadrao.emailAvaliador!}`,
                    {
                        headers: { "Authorization": `Bearer ${localStorage.getItem('authToken')}`, "Content-Type": "application/json" },
                    }
                );

                if (apiAvaliador.status === 200) {
                    const retornoAvaliador = (await apiAvaliador.json()) as { nomeAvaliador: string, emailAvaliador: string };
                    setNomeEmailAvaliador({ nomeUsuario: retornoAvaliador.nomeAvaliador!, emailUsuario: retornoAvaliador.emailAvaliador! });
                }
            }

            CarregarAvaliador();
        }

        const carregarResposta = async () => {
            const retornoResposta = await ObterRespostaProcurementAssessment(usuarioPadrao.email!);
            localStorage.setItem("avaliacaofinal", JSON.stringify(retornoResposta));
            setQuestionario(retornoResposta);
            setTabs({
                SoftSkill: retornoResposta.filter(n => n.idGrupoQuestionario === 11),
                HardSkill: retornoResposta.filter(n => n.idGrupoQuestionario === 12),
                Funcional: retornoResposta.filter(n => n.idGrupoQuestionario === 10)
            })
        }

        carregarResposta();

        document.body.style.backgroundImage = 'none';

        paginaInicio.current = "/home";

        configurarGrafico(document.getElementById("relatorio-total-final")?.innerHTML!);

        const CarregarTreinamento = async () => {

            const apiTreinamento = await fetch(`${process.env.REACT_APP_API!}/conclusao/carregar-treinamento-sugestivo?email=${usuarioPadrao.email!}`,
                {
                    headers: { "Authorization": `Bearer ${localStorage.getItem('authToken')}`, "Content-Type": "application/json" },
                }
            );

            if (apiTreinamento.status === 200) {
                const retornoTreinamentos = (await apiTreinamento.json()) as Array<string>;
                localStorage.setItem("treinamento", JSON.stringify(retornoTreinamentos));
                setTreinamento(retornoTreinamentos);
            }
        }

        CarregarTreinamento();



    }, []);

    const paginaHome = () => {
        document.location.href = paginaInicio.current;
        return;
    }


    const GerarPDF = async () => {

        const html = document.getElementsByTagName("html")[0].innerHTML;

        const apiPDF = await fetch(`${process.env.REACT_APP_API!}/RelatorioFinal`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ htmlGrafico: html })
        });

        if (apiPDF.ok) {
            let a = document.createElement('a');
            a.href = (await apiPDF.json() as { urlRetorno: string }).urlRetorno;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        }
    }

    return (
        <>
            <HeaderSistema home={paginaHome}></HeaderSistema>
            <Container id={"relatorio-total-final"}>
                <Row>
                    <Col md={1}></Col>
                    <Col style={{ paddingBottom: 20 }}>

                        {questionario && <RelatorioFinal questionario={questionario}
                            usuarioNomeEmail={usuarioNomeEmail.current}
                            tabs={tabs} />}

                        {questionario && <GraficoResultado nomeEmailAvaliador={nomeEmailAvaliador} usuarioAvaliador={usuario} usuarioPadrao={usuarioNomeEmail.current} avaliacaoFinal={questionario} />}

                        <hr />
                        {treinamentos && <TreinamentoSugestivo treinamento={treinamentos}></TreinamentoSugestivo>}
                        <hr />
                        <Button variant="danger" onClick={GerarPDF} className="ml-2 float-end" id="gerarPDF"><i className="fa fa-file-pdf-o"></i> PDF </Button>{' '}
                        <Button variant="primary" style={{ backgroundColor: "#684EA0", fontWeight: "bold" }} onClick={() => window.print()} className="float-end" id="gerarImpressao"><i className="fa fa-print"></i> Imprimir</Button>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}
