import React, { ChangeEvent } from "react";
import { GravarResposta, GravarRespostaComentario, IRespostaQuestionario, ObterQuestionarioProcurementAssessment } from "./questionario";
import { IQuestionario } from "../entidades/questionario";
import { IUsuario } from "../entidades/retorno-api";
import { CarregarConclusao, ConcluirQuestionarioConclusao, IniciarQuestionarioConclusao } from "./conclusao";
import { IConcluirQuestionario, IControlarVisibilidade, IEscolherNivel, IGravarComentario, ITiposConclusaoQuestionario, ITiposGravarResposta, ITiposVisibilibidadeQuestionario } from "./interfaces/homeservico";


export class HomeGravarRespostaServico implements IEscolherNivel, IGravarComentario {

    TiposGravarResposta: ITiposGravarResposta;

    constructor(_tiposGravarResposta: ITiposGravarResposta) {
        this.TiposGravarResposta = _tiposGravarResposta;
    }


    public async EscolherNivel(valor: ChangeEvent<HTMLSelectElement>): Promise<void> {

        const { respostaQuestionario }: { respostaQuestionario: IRespostaQuestionario; } = this.CarregarParametrosParaEnvio(valor);
        const respostaGravadaComSucesso = await GravarResposta(respostaQuestionario);

        if (!respostaGravadaComSucesso) {
            this.TiposGravarResposta.configurarErro({ temErro: true, mensagem: "Essa questão não foi gravada, tente novamente." });
            return;
        }

        this.TiposGravarResposta.configurarQuestionarioJaIniciado(true);
        this.TiposGravarResposta.questionario.current = await ObterQuestionarioProcurementAssessment();
        localStorage.setItem("questionario", JSON.stringify(this.TiposGravarResposta.questionario.current));
    }

    private CarregarParametrosParaEnvio(valor: ChangeEvent<HTMLSelectElement>) {
        const idPesquisa = Number(valor.target.id);
        const nivelEscolhido = Number(valor.target.value);
        this.TiposGravarResposta.configurarErro({ temErro: false, mensagem: "" });
        const usuarioPadrao = { email: this.TiposGravarResposta.usuarioContexto.user?.email, senha: "senha" } as IUsuario;
        const usuarioAvaliador = { email: this.TiposGravarResposta.usuarioContexto.user?.emailAvaliador, senha: "senha" } as IUsuario;
        const questionarioInterno = { id: idPesquisa, nivelEscolhido: nivelEscolhido, questao: "questao" } as IQuestionario;
        const respostaQuestionario: IRespostaQuestionario = { questionario: questionarioInterno, usuarioPadrao, usuarioAvaliador };
        this.TratarVisibilidade(nivelEscolhido);
        return { respostaQuestionario, nivelEscolhido };
    }

    private TratarVisibilidade(nivelEscolhido: number): void {

        if (nivelEscolhido > 0) {
            this.TiposGravarResposta.configurarRespostas(this.TiposGravarResposta.questionario.current!.filter(n => n.nivelEscolhido! > 0));
            this.TiposGravarResposta.configurarMostrarBotaoConclusaoVerRespostas(true);
        }
        else {
            this.TiposGravarResposta.configurarPendentes(this.TiposGravarResposta.questionario.current!.filter(n => n.nivelEscolhido! === 0));
            this.TiposGravarResposta.configurarMostrarBotaoConclusaoVerRespostas(false);
        }
    }

    public async GravarComentario(valor: React.FocusEvent<HTMLTextAreaElement, Element>): Promise<void> {
        const idPesquisa = Number(valor.target.id);
        const comentario = valor.target.value;
        const usuarioPadrao = { email: this.TiposGravarResposta.usuarioContexto.user?.email, senha: "senha" } as IUsuario;
        const usuarioAvaliador = { email: this.TiposGravarResposta.usuarioContexto.user?.emailAvaliador, senha: "senha" } as IUsuario;
        const questionarioInterno = { id: idPesquisa, comentario, questao: "questao" } as IQuestionario;
        const respostaQuestionario: IRespostaQuestionario = { questionario: questionarioInterno, usuarioPadrao, usuarioAvaliador };
        await GravarRespostaComentario(respostaQuestionario);
    }
}

export class HomeVisibilidadeServico implements IControlarVisibilidade {

    TipoVisibilidadeQuestionario: ITiposVisibilibidadeQuestionario;

    constructor(_tiposVisibilidade: ITiposVisibilibidadeQuestionario) {
        this.TipoVisibilidadeQuestionario = _tiposVisibilidade
    }

    public VerResposta(): void {

        if (this.TipoVisibilidadeQuestionario.questionarioJaIniciado) {
            this.TipoVisibilidadeQuestionario.configurarRespostas(this.TipoVisibilidadeQuestionario.questionario.current?.filter(n => n.nivelEscolhido! > 0));
            this.TipoVisibilidadeQuestionario.configurarVerRespostas(true);
            this.TipoVisibilidadeQuestionario.configurarVisibilidadeTabs(false);
            this.TipoVisibilidadeQuestionario.configurarVerPendentes(false);
        }
    }

    public VerPendentes(): void {

        if (this.TipoVisibilidadeQuestionario.questionarioJaIniciado) {
            this.TipoVisibilidadeQuestionario.configurarPendentes(this.TipoVisibilidadeQuestionario.questionario.current?.filter(n => n.nivelEscolhido! === 0));
            this.TipoVisibilidadeQuestionario.configurarVerPendentes(true);
            this.TipoVisibilidadeQuestionario.configurarVerRespostas(false);
            this.TipoVisibilidadeQuestionario.configurarVisibilidadeTabs(false);
        }
    }

    public async IniciarContinuarQuestionario(): Promise<void> {

        if (this.TipoVisibilidadeQuestionario.questionario.current?.every(n => n.nivelEscolhido! === 0)) {
            await IniciarQuestionarioConclusao();
        }

        this.TipoVisibilidadeQuestionario.configurarVisibilidadeTabs(true);
        this.TipoVisibilidadeQuestionario.configurarVerRespostas(false);
        this.TipoVisibilidadeQuestionario.configurarVerPendentes(false);
    }
}

export class HomeConclusaoQuestionarioServico implements IConcluirQuestionario {

    TiposConclusaoQuestionario: ITiposConclusaoQuestionario;

    constructor(_tipoConclusaoQuestionario: ITiposConclusaoQuestionario) {
        this.TiposConclusaoQuestionario = _tipoConclusaoQuestionario;
    }

    AbrirModalConcluirQuestionario(): void {
        this.TiposConclusaoQuestionario.configurarErro({ temErro: false, mensagem: '' });
        this.TiposConclusaoQuestionario.configurarVisibilidadeModal(true);
    }

    public async ConcluirQuestionario(): Promise<void> {

        const questionarioEstaIncompleto: boolean = this.TiposConclusaoQuestionario.questionario.length === 0 ||
            this.TiposConclusaoQuestionario.questionario.some(n => n.nivelEscolhido === 0 || n.nivelEscolhido === undefined);

        if (questionarioEstaIncompleto) {
            this.TiposConclusaoQuestionario.configurarErro({
                temErro: true, mensagem: ` Existem questões que ainda não foram respondidas, por favor 
            responda todas as questões para sua conclusão.`});

            return;
        }

        const retorno = await ConcluirQuestionarioConclusao();

        if (retorno.erro)
            this.TiposConclusaoQuestionario.configurarErro({ temErro: true, mensagem: "Não possível concluir seu questionário" });

        const retornoConclusao = await CarregarConclusao();
        this.TiposConclusaoQuestionario.configurarFlagConcluido(retornoConclusao.id > 0);
        this.TiposConclusaoQuestionario.configurarVisibilidadeModal(false);
    }
}