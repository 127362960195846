import { Alert, Col, Container, Row } from "react-bootstrap";
import { ChangeEvent } from "react";
import { IQuestionario } from "../entidades/questionario";
import TabQuestionario from "./TabQuestionario";

interface IPendenciasQuestionario {
    flagConcluido: boolean,
    EscolherNivel: (valor: ChangeEvent<HTMLSelectElement>) => Promise<void>,
    pendentes: IQuestionario[] | undefined,
    GravarTextoTextArea: (valor: React.FocusEvent<HTMLTextAreaElement>) => Promise<void>
}

export default function PendenciasQuestionario({ flagConcluido, EscolherNivel,
    pendentes, GravarTextoTextArea }: IPendenciasQuestionario) {

    const tabsPendente = {
        SoftSkill: pendentes?.filter(n => n.idGrupoQuestionario === 11),
        HardSkill: pendentes?.filter(n => n.idGrupoQuestionario === 12),
        Funcional: pendentes?.filter(n => n.idGrupoQuestionario === 10),
        Conclusao: ''
    }

    return (
        <>
            {pendentes!.length === 0 ? (
                <Container>
                    <Row className="mt-5">
                        <Col>
                            <Alert variant='info' dismissible>Não há questões pendentes</Alert>
                        </Col>
                    </Row>
                </Container> ) : (
                <TabQuestionario
                    EscolherNivel={EscolherNivel}
                    tabs={tabsPendente}
                    GravarTextoTextArea={GravarTextoTextArea}
                    flagConcluido={flagConcluido}
                />)
            }
        </>
    );
}