import { ChangeEvent, FormEvent, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { CadastroValidacao, ICadastroValidacao } from "../../validacoes/CadastroValidacao";
import { Usuario } from "../../entidades/usuario";
import { CadastrarNovaSenhaAsync } from "../../servicos/novasenha";
import Header from "../../components/Header";
import LogoTipoNLA from "../../components/LogoTipoNLA";

export default function NovaSenha() {

    const [tokenNovaSenhaEmail] = useSearchParams();
    const [msgErro, configurarErro] = useState('');
    const [senha, configurarSenha] = useState('');
    const [confirmacaoSenha, configurarConfirmacaoSenha] = useState('');
    const [exibirMensagem, setExibirMensagem] = useState<boolean>(false);

    const fecharModal = () => document.location.href = "/";
    
    const senhaInput = (e:ChangeEvent<HTMLInputElement>) => configurarSenha(e.target.value);

    const confirmacaoSenhaInput = (e:ChangeEvent<HTMLInputElement>) => {
      let valor = e.target.value;
      
      if(valor.length === 0) configurarErro("");
    
      configurarConfirmacaoSenha(e.target.value)
    }  
  
    const handleSubmit = async (e: FormEvent) => {
      
      e.preventDefault();
  
      try {
        let cadastroValidacao:ICadastroValidacao = new CadastroValidacao();
        cadastroValidacao.validacaoGeral(senha,confirmacaoSenha);
        
        const usuario: Usuario = {  
                email : tokenNovaSenhaEmail.get("email")!,
                senha,
                confirmacaoSenha,
                tokenNovaSenha : tokenNovaSenhaEmail.get("codigo")!.replaceAll(" ","+") 
            };

        const retornoAPI = await CadastrarNovaSenhaAsync(usuario);
  
        if (!retornoAPI.erro) {
          setExibirMensagem(true);
          return;
        }
  
        throw new Error(retornoAPI.mensagem);
  
      } catch (error) {
         configurarErro((error as Error).message);
      }
    }
    
    return (

        <>
            <Header />
            <div className="flex items-center justify-center mt-5">

                <form className="bg-black shadow-md rounded px-6 py-4"
                    onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-white text-[28px] font-bold mb-2">
                            Nova Senha
                        </label>
                    </div>

                    <div className="mb-4">
                        <input
                            className="input-login"
                            id="password"
                            type="password"
                            placeholder="Digite Sua Nova Senha"
                            required
                            value={senha}
                            onChange={senhaInput}
                        />
                    </div>
                    <div className="mb-4">

                        <input
                            className="input-login"
                            id="passwordConfirm"
                            type="password"
                            placeholder="Confirmar Nova Senha"
                            required
                            value={confirmacaoSenha}
                            onChange={confirmacaoSenhaInput}
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            className="btnRoxo font-sans shadow-xl hover:shadow-md"
                            type="submit"
                        >
                            Cadastrar
                        </button>

                    </div>

                    {msgErro.length > 0 && <div className="mt-2 mb-1 text-[#f00] font-bold w-[450px]">{msgErro}</div>}

                    <div className="mb-2 mt-2">

                        <label className="block text-white text-base mb-2 font-sans hover:underline">
                            <Link to="/" style={{ color: "white" }}> Voltar </Link>
                        </label>
                    </div>

                    <div className="mb-2"><LogoTipoNLA /></div>
                </form>

            </div>

            <Modal show={exibirMensagem} onHide={fecharModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: 14 }}>Mensagem</Modal.Title>
                </Modal.Header>
                <Modal.Body>Senha cadastrada com sucesso, agora você pode entrar no sistema.</Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={fecharModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}