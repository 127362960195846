export interface ICadastroValidacao{
    validacaoGeral(senha: string, senhaConfirmacao: string):void
}

export class CadastroValidacao implements ICadastroValidacao {
    
    validacaoGeral(senha: string, senhaConfirmacao: string): void {
        this.senhaParaCadastroEstaValida(senha);
        this.senhasEstaoIguais(senha,senhaConfirmacao);
    }

    senhaParaCadastroEstaValida(senha: string): void {
        const regexSenha = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;

        if (!regexSenha.test(senha))
            throw new Error("A senha deve ter: letra minuscula, maiuscula, número, caractere especial ex: (@,_,-,|) e minimo de 8 caracteres");
    }

    senhasEstaoIguais(senha: string, senhaConfirmacao: string): void {

        if (senha !== senhaConfirmacao)
            throw new Error("Senhas divergentes");
    }
}