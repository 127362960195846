import { Button } from "react-bootstrap";
import { HomeConclusaoQuestionarioServico } from "../servicos/HomeServico";
import { useEffect, useRef, useState } from "react";
import { IQuestionario } from "../entidades/questionario";
import ModalConclusao from "./ModalConclusao";
import { Link } from "react-router-dom";

interface IGrupoBotaoAcoes {
    IniciarContinuarQuestionario: () => Promise<void>,
    questionarioJaIniciado: boolean,
    VerRespostas: () => void,
    VerPendentes: () => void,
    flagConcluido: boolean,
    configurarFlagConcluido: React.Dispatch<React.SetStateAction<boolean>>,
    exibirAvaliacao: boolean,
    questionario: Array<IQuestionario>,
    email: string,
    token: string
}

export default function HomeGrupoBotaoAcoes({ IniciarContinuarQuestionario, VerPendentes,
    VerRespostas, questionarioJaIniciado, flagConcluido, configurarFlagConcluido,
    exibirAvaliacao, questionario, email, token }: IGrupoBotaoAcoes) {

    const [erros, configurarErro] = useState({ temErro: false, mensagem: "" });
    const [fecharModal, configurarVisibilidadeModal] = useState<boolean>(false);

    const homeServicoConclusao = new HomeConclusaoQuestionarioServico({
        configurarErro,
        configurarFlagConcluido,
        configurarVisibilidadeModal,
        questionario
    });


    const ConcluirQuestionario = async (): Promise<void> => await homeServicoConclusao.ConcluirQuestionario();
    const AbrirModalConcluirQuestionario = () => {
        // document.getElementById("boas-vindas")!.style.color = "#000";
        // document.body.style.backgroundImage = 'none';
        homeServicoConclusao.AbrirModalConcluirQuestionario();
    }

    // const linkRelatorioFinal = "/relatorio-final-avaliacao";
    
    return (
        <div className={'home-cards' + (exibirAvaliacao ? ' exibe-avaliacao' : '') + (!questionarioJaIniciado ? ' nao-iniciado' : '')}>
            {/* <Button variant="success" onClick={() => document.location.reload()} style={{borderRadius:"50%",background:"#684EA0", border:"none" }}><i className="fa fa-home"></i> </Button> */}
            {' '}

            {!flagConcluido &&
                <div className="card-home responder">
                    <Button className="font-sans hover:shadow-md"
                        onClick={IniciarContinuarQuestionario}
                        type="submit">
                        {questionarioJaIniciado ? ' Continuar Questionário' : ' Iniciar Questionário'}
                    </Button>
                </div>}
            {' '}
            {questionarioJaIniciado &&
                <div className="card-home respondidas">
                    <Button
                        onClick={VerRespostas}
                        className="font-sans hover:shadow-md"
                        type="submit">
                        Questões Concluídas
                    </Button>
                </div>
            }
            {' '}
            {questionarioJaIniciado && !flagConcluido &&
                <>
                    <div className="card-home pendentes">
                        <Button
                            onClick={VerPendentes}
                            className="font-sans hover:shadow-md"
                            type="submit" >
                            Pendentes
                        </Button>
                    </div>

                    <div className="card-home concluir">
                        <Button onClick={AbrirModalConcluirQuestionario}
                            className="font-sans hover:shadow-md"
                            type="submit"  >
                            Concluir
                        </Button>
                    </div>
                </>
            }
            {' '}
            {exibirAvaliacao &&
                <div className="card-home resultados">
                    <a rel="noreferrer" href={`http://dev.nextlevelacademy.com.br/RelatorioFinalGeral?email=${email!}&token=${localStorage.getItem('authToken')!}`} target="_blank"
                        className="btn btn-primary font-sans hover:shadow-md">
                        Ver resultados
                    </a>
                </div>
                //       <a href={`http://adm.assessmentdigital.com.br/RelatorioFinalGeral?email=${usuarioDados.email!}&token=${localStorage.getItem('authToken')!}`} target="_blank"
                //       className="font-sans shadow-xl hover:shadow-md btn btn-success"
                //   >
                //       <i className='fa fa-check'></i> Avaliação  </a>
            }
            <ModalConclusao TipoModal={{ erros, fecharModal, configurarVisibilidadeModal, ConcluirQuestionario }} />
        </div>
    )
}