import { IRetornoAPI } from "../entidades/retorno-api";
import { IAlteracaoDados } from "./interfaces/alterardadosservico";

const token = `Bearer ${localStorage.getItem('authToken')}`;

export async function AlterarDados(dados:IAlteracaoDados):Promise<IRetornoAPI> {
    
    const apiCadastro = await fetch(`${process.env.REACT_APP_API!}/usuario/alterar-dados`,{
        method: "POST",
        body: JSON.stringify(dados),
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
    });
    
    const retornoAPI = await apiCadastro.json() as IRetornoAPI;
    return retornoAPI;
}