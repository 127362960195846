import { IQuestionario } from "../entidades/questionario";
import { IRetornoAPI, IUsuario } from "../entidades/retorno-api";

export async function ObterQuestionarioProcurementAssessment() : Promise<Array<IQuestionario>> {

    const token = `Bearer ${localStorage.getItem('authToken')}`;
    const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
    const apiProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/questionario/procurement-assessment?email=${usuario.email}`,
    {
        method:"GET",
        headers: { "Authorization" : token! }
    });
    const retornoAPI = await apiProcurementAssessment.json() as Array<IQuestionario>;
    return retornoAPI;
}

export async function ObterQuestionarioQPerfil(){
    
}

export interface IRespostaQuestionario {
    questionario:IQuestionario,
    usuarioPadrao:IUsuario,
    usuarioAvaliador:IUsuario
}

async function GravarAlterarResposta(respostaQuestionario:IRespostaQuestionario,rota:string):Promise<IRetornoAPI>{

    if(respostaQuestionario.usuarioAvaliador.email === '')
        respostaQuestionario.usuarioAvaliador.email = 'sem-avaliador';

    const token = `Bearer ${localStorage.getItem('authToken')}`;
    const apiProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/resposta/${rota}`,
    {
        method:"POST",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
        body:JSON.stringify(respostaQuestionario)
    });
    
    const retornoAPI = await apiProcurementAssessment.json() as IRetornoAPI;

    return retornoAPI;

}

export async function GravarResposta(respostaQuestionario:IRespostaQuestionario):Promise<IRetornoAPI> {
    
    return GravarAlterarResposta(respostaQuestionario,"gravar");
}


export async function GravarRespostaComentario(respostaQuestionario:IRespostaQuestionario):Promise<IRetornoAPI> {

    return GravarAlterarResposta(respostaQuestionario,"gravar-comentario");
}

export async function GravarAlterarRespostaAvaliador(idRespostaUsuarioProcurementAssessment:number,
    nroNivelEscolhido:number,comentario?:string):Promise<IRetornoAPI>{
        
    const token = `Bearer ${localStorage.getItem('authToken')}`;
    const apiProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/resposta/gravar-nivel-comentario-avaliador`,
    {
        method:"POST",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
        body:JSON.stringify({
            idRespostaUsuarioProcurementAssessment,
            nroNivelEscolhido,
            comentario
        })
    });
    
    const retornoAPI = await apiProcurementAssessment.json() as IRetornoAPI;

    return retornoAPI;

}