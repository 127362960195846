import { Col, Container, Form, Row } from "react-bootstrap"
import { IQuestionario } from "../entidades/questionario"
import React, { ChangeEvent } from "react";
import Questao from "./Questao";

interface IQuestionarioDropDownTextArea {
    lista?: Array<IQuestionario> | undefined,
    EscolherNivel?: (valor: ChangeEvent<HTMLSelectElement>) => Promise<void> | undefined,
    desabilitarDropDown?: boolean | undefined,
    GravarTextarea?: (valor: React.FocusEvent<HTMLTextAreaElement>) => Promise<void> | undefined,
    tipoLista: string
}

export default function QuestionarioDropDownTextArea({ lista, EscolherNivel,
    desabilitarDropDown, GravarTextarea, tipoLista }: IQuestionarioDropDownTextArea) {

    return (
        <Container>
            <Row className="mb-4">
                <Col md={12} className="questoes-links">
                    <strong>
                        Questões: <br />
                        {lista?.map((questao: IQuestionario, i: number) =>
                            <React.Fragment key={questao.id}>
                                <a href={`#questao${tipoLista}${++i}`} title={questao.competencia} className="btn btn-success btn-sm"><strong>{i}</strong> </a> {' '}
                            </React.Fragment>
                        )}
                    </strong>
                </Col>
            </Row>
            {lista?.map((questao: IQuestionario, i: number) =>
                <Row key={questao.id}>
                    <Col md={12}>
                        <div className="questao-wrapper mb-4">
                            <br id={`questao${tipoLista}${++i}`} />
                            <Questao questao={questao} ancora={`#topoQuestao`}></Questao>
                            <div>
                                <Form.Select defaultValue={questao.nivelEscolhido} disabled={desabilitarDropDown} aria-label="Default select example" id={questao.id.toString()} onChange={EscolherNivel}  >
                                    <option value={0}> Escolha o seu nível</option>
                                    <option value={1}> 1 - Muito Pouco</option>
                                    <option value={2}> 2 - Pouco</option>
                                    <option value={3}> 3 - Mediano</option>
                                    <option value={4}> 4 - Bom</option>
                                    <option value={5}> 5 - Muito Bom</option>
                                </Form.Select>
                                <br />
                                <textarea disabled={desabilitarDropDown} className="form-control" id={questao.id.toString()} rows={4} cols={60} placeholder="Comentário Sobre Sua Escolha (Opcional)" onBlur={GravarTextarea} defaultValue={questao.comentario}></textarea>
                            </div>
                            {questao.nivelEscolhidoAvaliador! > 0 && <>
                            <hr />
                            <div>
                                <span><i>Avaliador:</i></span><br /><br />
                                <Form.Select defaultValue={questao.nivelEscolhidoAvaliador} disabled={desabilitarDropDown} aria-label="Default select example" id={questao.id.toString()} onChange={EscolherNivel}  >
                                    <option value={0}> Escolha o seu nível</option>
                                    <option value={1}> 1 - Muito Pouco</option>
                                    <option value={2}> 2 - Pouco</option>
                                    <option value={3}> 3 - Mediano</option>
                                    <option value={4}> 4 - Bom</option>
                                    <option value={5}> 5 - Muito Bom</option>
                                </Form.Select>
                                <br />
                                <textarea disabled={desabilitarDropDown} className="form-control" id={questao.id.toString()} rows={4} cols={60} placeholder="Comentário Sobre Sua Escolha (Opcional)" onBlur={GravarTextarea} defaultValue={questao.comentarioAvaliador}></textarea>
                            </div>
                            </>
                            }

                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}