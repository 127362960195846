import HeaderSistema from "../../components/HeaderSistema";
import { Col, Container, Row } from "react-bootstrap";
import { IQuestionario } from "../../entidades/questionario";
import TabQuestionarioAvaliador from "../../components/TabQuestionarioAvaliador";
import { useEffect, useState } from "react";
import { ObterRespostaProcurementAssessment } from "../../servicos/respostas";
import AvaliadorQuestionarioGrupoBotaoAcoes from "../../components/AvaliadorQuestionarioGrupoBotaoAcoes";
import ModalConclusao from "../../components/ModalConclusao";
import { ConcluirQuestionarioConclusaoAvaliador } from "../../servicos/conclusao";
import { Configuracao } from "../../configuracoes/Configuracao";

export default function AvaliadorQuestionario() {

    const [respostas, configurarRespostas] = useState<IQuestionario[]>([]);
    const usuarioPadrao = JSON.parse(localStorage.getItem("usuarioquestionario")!) as { nomeUsuario: string, emailUsuario: string };
    const [fecharModal, configurarVisibilidadeModal] = useState<boolean>(false);
    const [erros, configurarErros] = useState<{ temErro: boolean, mensagem: string }>();

    useEffect(() => {
        
        if (!usuarioPadrao.emailUsuario) {
            document.location.href = "/avaliador";
            return;
        }

        const retornoRespostas = async () => {
            configurarRespostas(await ObterRespostaProcurementAssessment(usuarioPadrao.emailUsuario));
        }

        retornoRespostas();
    }, []);

    if (!usuarioPadrao) {
        document.location.href = "/avaliador";
        return (<><h1 className="text-center">Redirecionando....</h1></>);
    }

    const tabs = {
        SoftSkill: respostas.filter(n => n.idGrupoQuestionario === 11),
        HardSkill: respostas.filter(n => n.idGrupoQuestionario === 12),
        Funcional: respostas.filter(n => n.idGrupoQuestionario === 10),
        Conclusao: "Conclusão"
    }

    const paginaHome = () => {
        document.location.href = "/avaliador";
        return;
    }

    async function ExibirRespondidas(): Promise<void> {
        configurarRespostas((await ObterRespostaProcurementAssessment(usuarioPadrao.emailUsuario)).filter(n => n.nivelEscolhidoAvaliador! > 0));
    }

    async function ExibirPendentes(): Promise<void> {
        configurarRespostas((await ObterRespostaProcurementAssessment(usuarioPadrao.emailUsuario)).filter(n => n.nivelEscolhidoAvaliador! === 0));
    }

    async function ExibirTudo(): Promise<void> {
        configurarRespostas(await ObterRespostaProcurementAssessment(usuarioPadrao.emailUsuario));
    }

    async function ConcluirQuestionario(): Promise<void> {

        try {

            if (respostas.some(n => n.nivelEscolhidoAvaliador === 0))
                throw new Error("Não é possível concluir, existem questões pendentes de avaliação.")

            const retorno = await ConcluirQuestionarioConclusaoAvaliador();

            if (!retorno.erro) {
                const usuarioPadrao = JSON.parse(localStorage.getItem("usuarioquestionario")!) as { nomeUsuario: string, emailUsuario: string };
                const retornoRespostasQuestionario = await ObterRespostaProcurementAssessment(usuarioPadrao.emailUsuario);
                configurarVisibilidadeModal(false);
                localStorage.setItem("avaliacaofinal", JSON.stringify(retornoRespostasQuestionario));
                document.location.href = Configuracao.UrlRelatorioFinal.replace("#email#",usuarioPadrao.emailUsuario).replace("#token#",localStorage.getItem("authToken")!);
                return;
            }

            throw new Error(retorno.mensagem);

        } catch (Erro) {
            configurarErros({ temErro: true, mensagem: (Erro as Error).message });
        }
    }

    function AbrirModalConclusao():void{
        configurarErros({temErro:false,mensagem:""});
        configurarVisibilidadeModal(true);
    }

    return (
        <>
            <HeaderSistema home={paginaHome} />
            <Container>
                <AvaliadorQuestionarioGrupoBotaoAcoes
                    ExibirTudo={ExibirTudo}
                    ExibirPendentes={ExibirPendentes}
                    ExibirRespondidas={ExibirRespondidas}
                    Concluir={AbrirModalConclusao}
                />
                <Row>
                    <Col className="mt-3">
                        <h3>Questionário de <em> {usuarioPadrao?.nomeUsuario} ({usuarioPadrao?.emailUsuario}) </em></h3>
                    </Col>
                </Row>
                {tabs && <TabQuestionarioAvaliador tabs={tabs} />}
            </Container>
            <ModalConclusao TipoModal={{ fecharModal, configurarVisibilidadeModal, ConcluirQuestionario, erros }} />
        </>
    )
}