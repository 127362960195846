import React, { Dispatch } from "react"
import { Alert, Button, Modal } from "react-bootstrap"

interface ITipoModal {
    fecharModal:boolean,
    configurarVisibilidadeModal : Dispatch<React.SetStateAction<boolean>>,
    ConcluirQuestionario : () => Promise<void>,
    erros? : { temErro: boolean, mensagem: string }
}


export default function ModalConclusao({TipoModal } : {TipoModal:ITipoModal} ){

    return(
        <Modal show={TipoModal.fecharModal} onHide={() => TipoModal.configurarVisibilidadeModal(false) }>
        <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 14 }}>Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Tem certeza que deseja concluir sua avaliação? Após essa ação, não será mais possível alterações das respostas.
            <hr />
            {TipoModal.erros?.temErro && <Alert variant="danger"> {TipoModal.erros?.mensagem} </Alert>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => TipoModal.configurarVisibilidadeModal(false)}>
                Cancelar
            </Button>
            <Button variant="success" onClick={TipoModal.ConcluirQuestionario}>
                OK
            </Button>
        </Modal.Footer>
    </Modal>
    )
}