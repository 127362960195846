import "./index.css"
import { Button, Col, Container, Row } from "react-bootstrap";
import GraficoResultado from "../../components/GraficoResultado";
import HeaderSistema from "../../components/HeaderSistema";
import { useEffect, useRef, useState } from "react";
import { IQuestionario } from "../../entidades/questionario";
import RelatorioFinalGeral from "../../components/RelatorioFinal";
import RelatorioFinal from "../../components/RelatorioFinal";
import { IUsuario } from "../../entidades/retorno-api";
import TreinamentoSugestivo from "../../components/TreinamentoSugestivo";

export default  function RelatorioFinalAvaliador()
{
    const [htmlGrafico, configurarGrafico] = useState<string>('');
    const [treinamentos,setTreinamento] = useState<Array<string>>([]);
    const paginaInicio = useRef<string>('');
    const [questionario,setQuestionario]  = useState<Array<IQuestionario>>([]);
    const [usuarioNomeEmail,setUsuarioNomeEmail] = useState<{nomeUsuario: string, emailUsuario:string}>(null!);
    const [usuarioAvaliador,setUsuarioAvaliador] = useState<IUsuario>(null!);
    const [tabs,setTabs] = useState<{
        SoftSkill: Array<IQuestionario>,
        HardSkill: Array<IQuestionario>,
        Funcional:Array<IQuestionario> 
    }>();

    useEffect(() => {
       
        document.body.style.backgroundImage = 'none';
        
        setUsuarioAvaliador(JSON.parse(localStorage.getItem("usuario")!) as IUsuario);

        const usuarioPadrao = JSON.parse(localStorage.getItem("usuarioquestionario")!) as {nomeUsuario:string , emailUsuario:string};

        setUsuarioNomeEmail(usuarioPadrao);
        const avaliacaoFinal:Array<IQuestionario> = JSON.parse(localStorage.getItem("avaliacaofinal")!) as Array<IQuestionario>;
        setQuestionario(avaliacaoFinal);
        setTabs({
            SoftSkill: avaliacaoFinal.filter(n => n.idGrupoQuestionario === 11),
            HardSkill: avaliacaoFinal.filter(n => n.idGrupoQuestionario === 12),
            Funcional: avaliacaoFinal.filter(n => n.idGrupoQuestionario === 10)
        });

        const CarregarTreinamento = async () => {

            const apiTreinamento = await fetch(`${process.env.REACT_APP_API!}/conclusao/carregar-treinamento-sugestivo?email=${usuarioPadrao.emailUsuario}`,
                {
                    headers: { "Authorization": `Bearer ${localStorage.getItem('authToken')}`, "Content-Type": "application/json" },
                }
            );

            if (apiTreinamento.status === 200) {
                const retornoTreinamentos = (await apiTreinamento.json()) as Array<string>;
                localStorage.setItem("treinamento", JSON.stringify(retornoTreinamentos));
                setTreinamento(retornoTreinamentos);
            }
        }

CarregarTreinamento();
        paginaInicio.current = "/avaliador";
        configurarGrafico(document.getElementById("relatorio-total-final")?.innerHTML!);

    },[]);

    const paginaHome = () => {
        document.location.href= paginaInicio.current;
        return;
    }


    const GerarPDF = async() => {

        const html = document.getElementsByTagName("html")[0].innerHTML;

         const apiPDF = await fetch(`${process.env.REACT_APP_API!}/RelatorioFinal`,{
            method:"POST",
            headers : { "Content-Type":"application/json"},
            body:JSON.stringify({htmlGrafico : html })
        });

        if(apiPDF.ok){    
        let a = document.createElement('a');
            a.href= (await apiPDF.json() as { urlRetorno:string }).urlRetorno;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        }
    }

    return(
        <>
     
            <HeaderSistema home={paginaHome}></HeaderSistema>
            
            <Container id={"relatorio-total-final"}>
                <Row>
                    <Col md={1}></Col>
                    <Col style={{paddingBottom:20}}>
                        <RelatorioFinal questionario={questionario} 
                         usuarioNomeEmail={usuarioNomeEmail} 
                         tabs={tabs} /> 
                        <GraficoResultado usuarioPadrao={usuarioNomeEmail}
                          avaliacaoFinal={questionario} usuarioAvaliador={usuarioAvaliador} />
                        <hr />
                        {treinamentos && <TreinamentoSugestivo treinamento={treinamentos}></TreinamentoSugestivo>}
                        <hr />       
                        <Button variant="danger" onClick={GerarPDF}  className="ml-2 float-end" id="gerarPDF"><i className="fa fa-file-pdf-o"></i> PDF </Button>{' '}
                        <Button variant="primary" onClick={() => window.print()}  className="float-end" id="gerarImpressao"><i className="fa fa-print"></i> Imprimir</Button>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container> 
        </>
    )
}
