import { Button, Col, Row } from "react-bootstrap";

interface IAvaliadorQuestionarioGrupoBotaoAcoes {
    ExibirPendentes: () => Promise<void>,
    ExibirRespondidas: () => Promise<void>,
    ExibirTudo: () => Promise<void>,
    Concluir: () => void
}

export default function AvaliadorQuestionarioGrupoBotaoAcoes({ ExibirRespondidas,
    ExibirPendentes, ExibirTudo, Concluir }: IAvaliadorQuestionarioGrupoBotaoAcoes) {

    return (
        <Row>
            <Col className="mt-2">
                <Button onClick={ExibirTudo} style={{background:"#684EA0" , border:"none"}}><i className="fa fa-bars"></i> Tudo </Button> {' '}
                <Button onClick={ExibirRespondidas} style={{background:"#684EA0" , border:"none"}}> <i className="fa fa-file-o"></i> Respondidas </Button>  {' '}
                <Button variant="danger" onClick={ExibirPendentes}> <i className="fa fa-exclamation"></i> Pendentes </Button> {' '}
                <Button variant="success" onClick={Concluir}><i className="fa fa-check"></i> Concluir </Button> {' '}
                <Button variant="success" onClick={() => document.location.reload()} style={{borderRadius:"50%",background:"#684EA0", border:"none" }}><i className="fa fa-home"></i> </Button>
            </Col>
        </Row>
    );
}