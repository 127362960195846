import { useContext, useState } from "react";
import { AuthContext } from "../contexts/Auth/AuthContext";
import { Col, Container, Row } from "react-bootstrap";
import ModalAlteracaoDados from "./ModalAlteracaoDados";


export default function HeaderSistema({ home }: { home: () => void }) {
    const auth = useContext(AuthContext);
    const [fecharModalAlteracao, configurarVisibilidadeModalAlteracao] = useState<boolean>(false);

    const handleLogout = async () => {
        auth.user = null;
        localStorage.clear();
        document.location.href = "/";
        return;
    }

    return (
        <header className="bg-[#9370DB] cabecalho">
            <div id="topoQuestao">
                <div className="row">
                    <div className="col-md-12">
                        <div className="header-wrapper">
                            <style>
                                {`@media print { .cabecalho{display: none;} #gerarPDF,#gerarImpressao{display:none;} }`}
                            </style>
                            <div className="header-logo">
                                <img
                                    src="logo-topo.png"
                                    width={170}
                                    style={{ cursor: "pointer" }}
                                    alt="Next Level Acedamy"
                                    onClick={home}
                                />
                            </div>
                            <div className="header-user">
                                <ul className="navbar-nav" style={{ cursor: 'pointer' }} >
                                    <li className="nav-item dropdown" >
                                        <a className="nav-link dropdown-toggle" style={{ color: "white" }}
                                            href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="icon-user"></span> </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ borderRadius: '0px' }}>
                                            <li> <strong className="dropdown-item" style={{ color: "#684EA0" }}><u>{auth.user?.nome}</u></strong></li>
                                            <li> <strong className="dropdown-item"> <i className="fa fa-envelope" style={{ color: "#684EA0" }}></i> <i>{auth.user?.email}</i></strong></li>
                                            <li> <a className="dropdown-item" href="#" onClick={() => configurarVisibilidadeModalAlteracao(true)}><i className="fa fa-cogs" style={{ color: "#684EA0" }}></i> Alterar Dados</a></li>
                                            <li> <hr className="dropdown-divider" /></li>
                                            <li> <a className="dropdown-item" href="#" onClick={home}><i className="fa fa-home" style={{ color: "#684EA0" }}></i> Home</a></li>
                                            <li> <a className="dropdown-item" href="#" onClick={handleLogout}><i className="fa fa-sign-out" style={{ color: "#684EA0" }}></i> Sair</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAlteracaoDados TipoModal={{ fecharModalAlteracao, configurarVisibilidadeModalAlteracao }} />
        </header>
    );
}