
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { IQuestionario } from "../entidades/questionario";
import { IUsuario } from "../entidades/retorno-api";
import { useEffect, useState } from "react";

interface IGraficoResultado {
    usuarioAvaliador?:IUsuario,
    usuarioPadrao?:{ nomeUsuario: string, emailUsuario: string },
    nomeEmailAvaliador?:{ nomeUsuario: string, emailUsuario: string },
    avaliacaoFinal:Array<IQuestionario>
}

export default function GraficoResultado({usuarioAvaliador,usuarioPadrao,
    nomeEmailAvaliador,avaliacaoFinal}:IGraficoResultado){

    const configuracao:ApexOptions = {  
        chart : {
         type: "radar"
       },
       labels: avaliacaoFinal.map((questao,indice) => questao.competencia!) ,
       yaxis : { show:false } ,
       xaxis : {labels : {
        show:true,
        style:{
            colors : avaliacaoFinal.map((questao,indice) => "#000"),
            fontWeight:"bold"
        }
       }}
     };

    let dados:ApexAxisChartSeries;

    if(usuarioAvaliador?.flagAvaliador || usuarioAvaliador?.emailAvaliador !== ""){

        let gestor:string = `${nomeEmailAvaliador?.nomeUsuario} - (${nomeEmailAvaliador?.emailUsuario})` ;

        if(usuarioAvaliador?.flagAvaliador === true){
          gestor = `${usuarioAvaliador?.nome} - (${usuarioAvaliador?.email})`; 
        }

        dados =
            [
                {
                    name: `<strong style="color:#000;">Usuário: ${usuarioPadrao?.nomeUsuario} - (${usuarioPadrao?.emailUsuario})</strong>`,
                    data: avaliacaoFinal.map((niveis,indice) => niveis.nivelEscolhido!),
                    
                },
                {
                    name: `<strong style="color:#000;">Gestor: ${gestor})</strong>`,
                    data: avaliacaoFinal.map((niveis,indice) => niveis.nivelEscolhidoAvaliador!)
                }
            ];
    }else{
        dados = 
            [
                {
                    name: `<strong style="color:#000;">Usuário: ${usuarioPadrao?.nomeUsuario} - (${usuarioPadrao?.emailUsuario})</strong>`,
                    data: avaliacaoFinal.map((niveis,indice) => niveis.nivelEscolhido!),
                    
                },
                {
                    name: `<strong style="color:#000;">Benckmark</strong>`,
                    data: avaliacaoFinal.map((niveis,indice) => niveis.nivelBenchMark!)
                }
            ];
    }

    return(<>
    <div className="grafico">
    <style>
            {`@media print {.grafico{ color: #000 !important;}}`}
           </style>
           <Chart
              options={configuracao}
              series={dados}
              type="radar"
              width="100%"
            />
    </div>
    
    </>)
}