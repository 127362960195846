import { Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { IQuestionario } from "../entidades/questionario"
import { ChangeEvent } from "react";
import Questao from "./Questao";
import { title } from "process";

interface IQuestionarioDropDownTextAreaAvaliador {
    lista?: Array<IQuestionario> | undefined,
    EscolherNivel?: (valor: ChangeEvent<HTMLSelectElement>) => Promise<void> | undefined,
    GravarTextarea?: (valor: React.FocusEvent<HTMLTextAreaElement>) => Promise<void> | undefined,
    tipoLista:string,
    desativarForm?:boolean
}

export default function QuestionarioDropDownTextAreaAvaliador({ lista,
    EscolherNivel, GravarTextarea, tipoLista,desativarForm }: IQuestionarioDropDownTextAreaAvaliador) {

    return (
        <Container>
                        <strong>
                        Questões: <br />
                        {lista?.map((questao: IQuestionario, i: number) => 
                        <>
                            <a href={`#questao${tipoLista}${++i}`} title={questao.competencia}  className="btn btn-success btn-sm"><strong>{i}</strong> </a> {' '} 
                            </>
                        )}
                        </strong>
                   
               
            <Row className='mx-2 mt-5 pb-20'>
                {lista?.map((questao: IQuestionario, i: number) =>
                    <Row key={questao.id}>
                        <Col md={12}>
                            <br id={`questao${tipoLista}${++i}`} />
                                <Questao questao={questao} ancora={`#topoQuestao`}></Questao>
                            <br />
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <div style={{background:"#52C0A4",padding:"5px",color:"#fff",fontWeight:"bold",fontSize:"20px"}} className="text-center">Resposta Aluno</div>
                                    <div style={{height:"20px"}}></div>
                                    <Form.Select defaultValue={questao.nivelEscolhido} disabled={true} aria-label="Default select example" id={questao.id.toString()}>
                                        <option value={0}> Escolha o seu nível</option>
                                        <option value={1}> 1 - Muito Pouco</option>
                                        <option value={2}> 2 - Pouco</option>
                                        <option value={3}> 3 - Mediano</option>
                                        <option value={4}> 4 - Bom</option>
                                        <option value={5}> 5 - Muito Bom</option>
                                    </Form.Select>
                                </Row>
                                <Row className="mt-2">
                                    <textarea disabled={true} className="form-control" id={questao.id.toString()} rows={4} cols={60} placeholder="Comentário Sobre Sua Escolha (Opcional)" defaultValue={questao.comentario}></textarea>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={1}>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                <div style={{background:"#52C0A4",padding:"5px",color:"#fff",fontWeight:"bold",fontSize:"20px"}} className="text-center">Avaliador</div>
                                <div style={{height:"20px"}}></div>

                                    <Form.Select defaultValue={questao.nivelEscolhidoAvaliador} aria-label="Default select example" id={questao.idResposta?.toString()} onChange={EscolherNivel} disabled={desativarForm}  >
                                        <option value={0}> Escolha o seu nível</option>
                                        <option value={1}> 1 - Muito Pouco</option>
                                        <option value={2}> 2 - Pouco</option>
                                        <option value={3}> 3 - Mediano</option>
                                        <option value={4}> 4 - Bom</option>
                                        <option value={5}> 5 - Muito Bom</option>
                                    </Form.Select>
                                </Row>
                                <Row className="mt-2">
                                    <textarea className="form-control" id={questao.idResposta?.toString()} rows={4} cols={60} placeholder="Comentário Sobre Sua Escolha (Opcional)" onBlur={GravarTextarea} defaultValue={questao.comentarioAvaliador} disabled={desativarForm}></textarea>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                )}
            </Row>
        </Container>
    );
}