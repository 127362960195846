import { Alert, Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import HeaderSistema from "../../components/HeaderSistema";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { IUsuario } from "../../entidades/retorno-api";
import { CSVLink } from "react-csv";

interface ICompentenciaLote {
    id: number,
    competencia: string,
    nivel?: number,
    comentario?: string,
}

interface IUsuarioLote {

    IdUsuarioResposta:number,
    IdUsuario:number,
    IdQuestionario:number,
    Nome:string,
    Email:string,
    Avaliador:string, 
    Competencia:string, 
    Questao:string,
    NroNivel:number,
    NroNivelAvaliador:number,
    ComentarioAvaliador:string
}

export default function AvaliacaoLote() {
    const [listaUsuario, ConfigurarListaUsuario] = useState<Array<IUsuarioLote>>([]);
    const [exibirModalLote, ConfigurarModalLote] = useState<boolean>(false);
    const [mensagemModalLote, ConfigurarMensagemModalLote] = useState<string>('');
    const [exibirBotaoErro, ConfigurarBotaoErro] = useState<boolean>(false);
    const [exibirBotaoConclusao, ConfigurarBotaoConclusao] = useState<boolean>(false);
    const [exibirBotaoOkSucesso, ConfigurarBotaoOkSucesso] = useState<boolean>(false);
    const [exibirBotaoFechar, ConfigurarBotaoFechar] = useState<boolean>(true);
    const [usuarioLogado, ConfigurarUsuario] = useState<IUsuario>(null!);
    const refArquivo = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
        ConfigurarUsuario(usuario);
        try {
            
            const ObterUsuariosPorEmailAvaliadorLote = async (usuario: string) => {

                const apiUsuariosVinculados = await fetch(`${process.env.REACT_APP_API!}/avaliador/filtrar-usuarios-vinculados-lote?emailAvaliador=${usuario}`,
                    {
                       method:"GET",
                        headers: { "Authorization":  "Bearer " + localStorage.getItem("authToken")! }
                    });

                if (apiUsuariosVinculados.status !== 200) {
                    throw new Error("Erro");
                }

                const retornoAPI = await apiUsuariosVinculados.json() as Array<IUsuarioLote>;
                ConfigurarListaUsuario(retornoAPI);
            }

            ObterUsuariosPorEmailAvaliadorLote(usuario.email!);
        } catch (erro) {
            ConfigurarListaUsuario([]);
        }

        document.body.style.background = "none";
        
    }, []);

    const paginaHome = () => {
        document.body.style.background = "none";
        document.location.href = "/avaliador";
        return;
    }

    function AbrirModalAvaliacaoEmLote(): void {
        ConfigurarModalLote(true);
        ConfigurarMensagemModalLote("Confirmar Avaliação Em Lote?");
        ConfigurarBotaoConclusao(true);
        ConfigurarBotaoErro(false);
    }


     function AbrirUpload(): void {
        document.getElementById("arquivo")?.click();
    }

   async function AplicarAvaliacaoLote(event:React.ChangeEvent<HTMLInputElement>):Promise<void> {
        
        let formArquivo = new FormData();
        let arquivo = event.target.files?.item(0);
        formArquivo.append("email",usuarioLogado.email!);
        formArquivo.append("arquivo",arquivo!);

        if(arquivo?.size! <= 0){

            ConfigurarMensagemModalLote("Você deve escolher o arquivo para envio");
            ConfigurarBotaoErro(true);
            ConfigurarBotaoConclusao(false);
            ConfigurarBotaoOkSucesso(false);

            return;
        }
        
        if(arquivo?.type !== "text/csv"){

            ConfigurarMensagemModalLote("Formato de Arquivo inválido (deve ser apenas csv)");
            ConfigurarBotaoErro(true);
            ConfigurarBotaoConclusao(false);
            ConfigurarBotaoOkSucesso(false);
            return;
        }

         const apiAvaliacaoEmLote = await fetch(`${process.env.REACT_APP_API!}/avaliador/executar-avaliacao-em-lote`,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + localStorage.getItem("authToken")! },
                body: formArquivo
            });


        const retornoAPI = await apiAvaliacaoEmLote.json() as { flagErro: boolean, mensagem: string };

        if (!retornoAPI.flagErro) {
            ConfigurarMensagemModalLote("Avaliação Em Lote Concluída Com Sucesso");
            ConfigurarBotaoConclusao(false);
            ConfigurarBotaoOkSucesso(true);
            ConfigurarBotaoFechar(false);
        } else {
            ConfigurarMensagemModalLote(retornoAPI.mensagem);
            ConfigurarBotaoErro(true);
            ConfigurarBotaoConclusao(false);
            ConfigurarBotaoOkSucesso(false);
        }
        
    }

    const FecharModal = () => {
        ConfigurarModalLote(false);
        refArquivo.current!.value = "";
    }

    return (
        <>
            <HeaderSistema home={paginaHome} />
            <Container className='mb-10 mt-10'>
               
                <Alert variant="warning">
                    Nesse processo, a opção "Baixar Usuários Em Lote" irá fazer download de um CSV, nesse arquivo terão apenas usuários que já concluiram suas avalições.
                    Na opção "Enviar Avaliação Em Lote", deverá ser enviado o mesmo arquivo que foi baixado, porém neste caso poderá ir com as novas notas de avaliação do avaliador.
                </Alert>
               
               {listaUsuario.length > 0 ? (
               <>

               <input type="file" className="d-none" id="arquivo" multiple={false} onChange={AplicarAvaliacaoLote} ref={refArquivo} />
                <CSVLink data={listaUsuario} separator={";"} filename="questionario-lote" className="btn btn-info"><i className="fa fa-users"></i> Baixar Usuários Em Lote</CSVLink>
               {' '}
                <Button variant="success" onClick={AbrirModalAvaliacaoEmLote} ><i className="fa fa-file"></i> Enviar Avaliação Em Lote </Button>
                </>
               ) : (
                <Alert variant="danger">
                    No momento você não têm usuários para serem avaliados.
                </Alert>
               )

               }
               
            </Container>
            <Modal show={exibirModalLote}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: 14 }}>Mensagem</Modal.Title>
                </Modal.Header>
                <Modal.Body>{mensagemModalLote}</Modal.Body>
                <Modal.Footer>
                    {exibirBotaoFechar &&
                        <Button variant="warning" onClick={FecharModal}>
                            <i className="fa fa-close"></i> Não
                        </Button>}
                    {exibirBotaoErro &&
                        <Button variant="danger" onClick={FecharModal}>
                            <i className="fa fa-exclamation"></i> OK
                        </Button>}

                    {exibirBotaoConclusao &&
                        <Button variant="success" onClick={AbrirUpload}>
                            <i className="fa fa-save"></i> Sim
                        </Button>}

                    {exibirBotaoOkSucesso &&
                        <Button variant="success" onClick={() => document.location.href = "/avaliador"}>
                            <i className="fa fa-check"></i> OK
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

