
// import 'bootstrap/dist/css/bootstrap.css'
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Cadastro from './pages/Cadastro';
import NovaSenha from './pages/NovaSenha';
import EsqueciMinhaSenha from './pages/EsqueciMinhaSenha';
import { Login } from './pages/Login';
import Home from './pages/Home';
import Private from './pages/Private';
import PaginaNaoEncontrada from './pages/PaginaNaoEncontrada';
import Avaliador from './pages/Avaliador';
import AvaliadorQuestionario from './pages/AvaliadorQuestionario';
import RelatorioFinalAvaliador from './pages/AvaliadorRelatorioFinal';

import RelatorioFinalUsuario from './pages/UsuarioPadraoRelatorioFinal';
import RequireAuth from './contexts/Auth/RequireAuth';
import AvaliacaoLote from './pages/AvalicaoLote';
import AvaliadorQuestionarioDetalhe from './pages/AvaliadorQuestionarioDetalhe';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<Login />} />
        <Route path="/cadastro" element={<Cadastro />} />
              <Route path="/novasenha" element={<NovaSenha />} />
              <Route path="/avaliacao-lote" element={<RequireAuth><AvaliacaoLote /></RequireAuth>} />
        <Route path="/esqueciminhasenha" element={<EsqueciMinhaSenha />} />
        <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
        <Route path="/avaliador" element={<RequireAuth><Avaliador /></RequireAuth>} />
        <Route path="/private" element={<RequireAuth><Private /></RequireAuth>} />
        <Route path="/avaliador-questionario" element={<RequireAuth><AvaliadorQuestionario /></RequireAuth>} />
        <Route path="/avaliador-questionario-detalhe" element={<RequireAuth><AvaliadorQuestionarioDetalhe /></RequireAuth>} />
        <Route path="/relatorio-final" element={<RequireAuth><RelatorioFinalAvaliador /></RequireAuth>} />
        <Route path="/relatorio-final-avaliacao" element={<RequireAuth><RelatorioFinalUsuario /></RequireAuth>} />
        
        <Route path="*" element={<PaginaNaoEncontrada />} />
      </Routes>
    </div>
  );
}

export default App;
