import HeaderSistema from "../../components/HeaderSistema";
import { Col, Container, Row } from "react-bootstrap";
import { IQuestionario } from "../../entidades/questionario";
import TabQuestionarioAvaliador from "../../components/TabQuestionarioAvaliador";
import { useEffect, useState } from "react";
import { ObterRespostaProcurementAssessment } from "../../servicos/respostas";

export default function AvaliadorQuestionarioDetalhe() {

    const [respostas, configurarRespostas] = useState<IQuestionario[]>([]);
    
    useEffect(() => {
      
        const emailBusca = new URLSearchParams(window.location.search).get("usuario");

        const retornoRespostas = async () => {
            configurarRespostas(await ObterRespostaProcurementAssessment(emailBusca!));
        }

        retornoRespostas();
    }, []);


    const tabs = {
        SoftSkill: respostas.filter(n => n.idGrupoQuestionario === 11),
        HardSkill: respostas.filter(n => n.idGrupoQuestionario === 12),
        Funcional: respostas.filter(n => n.idGrupoQuestionario === 10),
        Conclusao: "Conclusão"
    }

    const paginaHome = () => {
        document.location.href = "/avaliador";
        return;
    }

    return (
        <>
            <HeaderSistema home={paginaHome} />
            <Container>
                <Row>
                    <Col className="mt-3">
                        <h3>Questionário de <em> {new URLSearchParams(window.location.search).get("usuario")}  </em></h3>
                    </Col>
                </Row>
                {tabs && <TabQuestionarioAvaliador tabs={tabs} desativarForm={true} />}
            </Container>
        </>
    )
}