import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import HeaderSistema from '../../components/HeaderSistema';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { Col, Container, Row } from 'react-bootstrap';
import { ObterQuestionarioProcurementAssessment } from '../../servicos/questionario';
import { IQuestionario } from '../../entidades/questionario';
import { CarregarConclusao } from '../../servicos/conclusao';
import './index.css'
import { HomeGravarRespostaServico, HomeVisibilidadeServico } from '../../servicos/HomeServico';
import { IEscolherNivel, IGravarComentario } from '../../servicos/interfaces/homeservico';
import TabQuestionario from '../../components/TabQuestionario';
import HomeGrupoBotaoAcoes from '../../components/HomeGrupoBotaoAcoes';
import RespostasQuestionario from '../../components/RespostasQuestionario';
import PendenciasQuestionario from '../../components/PendenciasQuestionario';
import ChamadaInicial from '../../components/ChamadaInicial';
import { IUsuario } from '../../entidades/retorno-api';

import pontuacoes from '../../assets/images/respostas-questionario.svg';
import { IConclusao } from '../../entidades/conclusao';

export default function Home() {
    const usuarioContexto = useContext(AuthContext);
    const [flagTabsVisivel, configurarVisibilidadeTabs] = useState<boolean>(false);
    const [questionarioJaIniciado, configurarQuestionarioJaIniciado] = useState(false);
    const [flagConcluido, configurarFlagConcluido] = useState(false);
    const [flagVerPendentes, configurarVerPendentes] = useState(false);
    const [flagVerRespostas, configurarVerRespostas] = useState(false);
    const [mostrarBotaoConclusaoVerRespostas, configurarMostrarBotaoConclusaoVerRespostas] = useState<boolean>(true);
    const [erros, configurarErro] = useState({ temErro: false, mensagem: "" });
    const questionario = useRef<Array<IQuestionario>>();
    const [respostas, configurarRespostas] = useState<Array<IQuestionario>>();
    const [pendentes, configurarPendentes] = useState<Array<IQuestionario>>();
    const [verApresentacao, configurarVisibilidadeApresentacao] = useState<boolean>(true);
    const avaliacaofinal = useRef<Array<IQuestionario>>(null!);
    const [exibirAvaliacao, setUsuarioAvaliacao] = useState<boolean>(false);
    const [usuarioDados, setUsuario] = useState<IUsuario>(JSON.parse(localStorage.getItem("usuario")!) as IUsuario);
    const [conclusao,setConclusao] = useState<IConclusao>(null!);

    useEffect(() => {
    
        const retornoQuestionario = async () => {

            questionario.current = await ObterQuestionarioProcurementAssessment();

            const ehUsuarioSemAvaliadorTendoConcluidoQuestionario = usuarioDados?.emailAvaliador === "" && questionario.current.every(n => n.flagConcluido === true);
            const ehUsuarioComAvaliadorTendoAvaliacaoConcluida = questionario.current.every(n => n.flagConcluidoAvaliador);
            const exibirIdependenteDeUsuario = (ehUsuarioSemAvaliadorTendoConcluidoQuestionario || ehUsuarioComAvaliadorTendoAvaliacaoConcluida);
            setUsuarioAvaliacao(usuarioDados?.flagVisualizarResultado! && exibirIdependenteDeUsuario);

            const retornoConclusao = await CarregarConclusao();
            configurarQuestionarioJaIniciado(questionario.current.some((n) => n.nivelEscolhido! > 0));
            configurarFlagConcluido(retornoConclusao.id > 0 && retornoConclusao.flagConcluido);
            setConclusao(retornoConclusao);
        }

        retornoQuestionario();

    }, []);


    const homeServico = new HomeGravarRespostaServico({
        configurarErro,
        configurarMostrarBotaoConclusaoVerRespostas,
        configurarPendentes,
        configurarQuestionarioJaIniciado,
        configurarRespostas,
        questionario,
        usuarioContexto
    });

    const homeServicoVibibilidade = new HomeVisibilidadeServico({
        configurarPendentes,
        configurarRespostas,
        configurarVerPendentes,
        configurarVerRespostas,
        configurarVisibilidadeTabs,
        questionario,
        questionarioJaIniciado
    });

    const homeGravarRespostaNivel: IEscolherNivel = homeServico;
    const homeGravarRespostaComentario: IGravarComentario = homeServico;
    const EscolherNivel = async (valor: ChangeEvent<HTMLSelectElement>): Promise<void> => await homeGravarRespostaNivel.EscolherNivel(valor);
    const GravarTextoTextArea = async (valor: React.FocusEvent<HTMLTextAreaElement>): Promise<void> => await homeGravarRespostaComentario.GravarComentario(valor);


    function MudarEstiloFundo() {
        configurarVisibilidadeApresentacao(false);
    }

    const IniciarContinuarQuestionario = async (): Promise<void> => {
        MudarEstiloFundo();
        await homeServicoVibibilidade.IniciarContinuarQuestionario();
    }
    const VerPendentes = () => {
        MudarEstiloFundo();
        homeServicoVibibilidade.VerPendentes();
    }
    const VerRespostas = () => {
        MudarEstiloFundo();
        homeServicoVibibilidade.VerResposta();
    }

    const tabs = {
        SoftSkill: questionario.current?.filter(n => n.idGrupoQuestionario === 11),
        HardSkill: questionario.current?.filter(n => n.idGrupoQuestionario === 12),
        Funcional: questionario.current?.filter(n => n.idGrupoQuestionario === 10)
    }

    const paginaHome = () => {
        document.location.href = "/home";
        return;
    }

    const RetornarStatusSituacao = () => {
         if(!conclusao.flagConcluido)
            return "Aguardando Respostas";
        if(conclusao.flagConcluido && !conclusao.flagConcluidoAvaliador && usuarioDados.emailAvaliador !== "")
            return "Aguardando Avaliador";
        return "Processo Concluído";
    }

    return (
        <>
            <HeaderSistema home={paginaHome} />
            <Container>
                <Row className='mt-3 pt-3'>
                    <Col md={12} id="boas-vindas">
                        <h1 className="font-sans">Seja bem-vindo, {usuarioContexto.user?.nome}👋 </h1>
                        <h5>Status Questionário : {conclusao && RetornarStatusSituacao()} </h5>
                        {!exibirAvaliacao && <p>Escolha a ação desejada.</p>}
                        {exibirAvaliacao && <p>Avaliação concluída, clique abaixo para visualizar o resultado.</p>}
                    </Col>
                </Row>
                <Row className='mt-3 mb-3'>
                    <Col md={12}>
                        {usuarioDados &&
                            <HomeGrupoBotaoAcoes
                                configurarFlagConcluido={configurarFlagConcluido}
                                flagConcluido={flagConcluido}
                                IniciarContinuarQuestionario={IniciarContinuarQuestionario}
                                VerPendentes={VerPendentes}
                                VerRespostas={VerRespostas}
                                questionarioJaIniciado={questionarioJaIniciado}
                                questionario={questionario.current!}
                                exibirAvaliacao={exibirAvaliacao}
                                email={usuarioDados.email!}
                                token={localStorage.getItem('authToken')!}
                            />}
                    </Col>
                </Row>

                {!flagConcluido && (flagTabsVisivel || flagVerRespostas || flagVerPendentes) &&
                    <p><strong className="primary"><i className="fa fa-info-circle"></i>&nbsp;Atenção: &nbsp;</strong>As respostas inseridas nesta avaliação serão automaticamente salvas e sempre que sair sem finalizar poderá continuar de onde parou.</p>
                }

                {flagTabsVisivel && !flagConcluido &&
                    <TabQuestionario
                        EscolherNivel={EscolherNivel}
                        GravarTextoTextArea={GravarTextoTextArea}
                        flagConcluido={flagConcluido}
                        tabs={tabs}
                    />
                }

                {flagVerRespostas &&
                    <RespostasQuestionario
                        EscolherNivel={EscolherNivel}
                        GravarTextoTextArea={GravarTextoTextArea}
                        flagConcluido={flagConcluido}
                        respostas={respostas}
                    />
                }
                {flagVerPendentes &&
                    <PendenciasQuestionario
                        EscolherNivel={EscolherNivel}
                        GravarTextoTextArea={GravarTextoTextArea}
                        flagConcluido={flagConcluido}
                        pendentes={pendentes}
                    />
                }

                {!exibirAvaliacao &&
                    <Row className='pb-10'>
                        <Col md={12}>
                            <div className='instrucoes-home'>
                                <div className='instrucoes-home-inner'>
                                    <h3>Instruções 💡</h3>
                                    <p>
                                        Preencher cada questão considerando o nível de experiência e conhecimento do tema, sendo a classificação dividida de 01 a 05:
                                    </p>
                                    <img src={pontuacoes} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>

        </>
    );
}