
interface ITreinamento {
    treinamento:string[]
}

export default function TreinamentoSugestivo({treinamento}:ITreinamento){

    return(
            <div>
                <h5 style={{color:"#684EA0",lineHeight:"50px"}}>De acordo com essa avaliação, segue abaixo as sugestões de treinamentos:</h5>
            
                {treinamento.map((valor,indice) => <>
                    <strong key={indice} className="list-group-item"><i className="fa fa-circle" style={{color:"#684EA0"}}></i> {valor}</strong><br />
                </>)}
                
            </div>
    );
}