export default function LogoTipoNLA(){

    return(
   <a href="http://www.nextlevelacademy.com.br" target="_blank" rel="noreferrer">
        <img
        src="/logopa.png"
        width={250}
        
        alt="Next Level Acadamy"
        
        />
    </a>
    );
}