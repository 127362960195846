import { IConclusao } from "../entidades/conclusao";
import { IRetornoAPI, IUsuario } from "../entidades/retorno-api";

const token = `Bearer ${localStorage.getItem('authToken')}`;
const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;

export async function IniciarQuestionarioConclusao() : Promise<IRetornoAPI> {

    const conclusao:IConclusao = { 
        flagConcluido : false,
        flagAvaliadoEmConformidade : false,
        flagConcluidoAvaliador : false,
        id: 0
      };

      return await GravarConclusao(conclusao);
}


export async function ConcluirQuestionarioConclusao() : Promise<IRetornoAPI> {

    const conclusao:IConclusao = { 
        flagConcluido : true,
        flagAvaliadoEmConformidade : false,
        flagConcluidoAvaliador : false,
        id: 0
      };

      return await GravarConclusao(conclusao);
}

export async function ConcluirQuestionarioConclusaoAvaliador() : Promise<IRetornoAPI> {

    const usuarioPadrao = JSON.parse(localStorage.getItem("usuarioquestionario")!) as {nomeUsuario:string, emailUsuario:string};

    const apiConclusaoProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/conclusao/concluir-avaliacao`,
    {
        method:"POST",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
        body : JSON.stringify({emailUsuarioPadrao : usuarioPadrao.emailUsuario  , emailUsuarioAvaliador : usuario.email})
    });
    
    const retornoAPI = await apiConclusaoProcurementAssessment.json() as IRetornoAPI;
    return retornoAPI;
}

async function GravarConclusao(conclusao:IConclusao) : Promise<IRetornoAPI> {

    conclusao.emailUsuarioPadrao = usuario.email;
    conclusao.emailUsuarioAvaliador = usuario.emailAvaliador;
    
    const apiConclusaoProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/conclusao/gravar`,
    {
        method:"POST",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" },
        body : JSON.stringify(conclusao)
    });
    const retornoAPI = await apiConclusaoProcurementAssessment.json() as IRetornoAPI;
    return retornoAPI;
}


export async function CarregarConclusao() : Promise<IConclusao> {

    if(usuario.emailAvaliador === "")
        usuario.emailAvaliador = "sem-avaliador";
    
    const apiConclusaoProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/conclusao/carregar?email=${usuario.email}&emailAvaliador=${usuario.emailAvaliador}`,
    {
        method:"GET",
        headers: { "Authorization" : token! , "Content-Type" : "application/json" }
    });

    if(apiConclusaoProcurementAssessment.status === 204) {
        return { id:0, 
                flagConcluido:false, 
                flagAvaliadoEmConformidade:false, 
                flagConcluidoAvaliador : false,
                emailUsuarioAvaliador : usuario.emailAvaliador,
                emailUsuarioPadrao : usuario.email
            } as IConclusao;
    }
    
    const retornoConclusao = await apiConclusaoProcurementAssessment.json() as IConclusao;
    return retornoConclusao;
}