import { ChangeEvent, FormEvent, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { Usuario } from "../../entidades/usuario";
import { EnviarEsqueciMinhaSenha } from "../../servicos/esqueciminhasenha";
import LogoTipoNLA from "../../components/LogoTipoNLA";


export default function EsqueciMinhaSenha() {

    const [msgErro, configurarErro] = useState('');
    const [email, configurarEmail] = useState('');
    const [exibirMensagem, setExibirMensagem] = useState<boolean>(false);
    const [mensagemRetornoAPI, configurarMensagem] = useState<string>('');
    const [emailUrl, setSearchParams] = useSearchParams();

    const fecharModal = () => document.location.href = "/";

    const emailInput = (e: ChangeEvent<HTMLInputElement>) => configurarEmail(e.target.value);

    const handleSubmit = async (e: FormEvent) => {

        e.preventDefault();

        try {

            const usuario: Usuario = {
                email: email ? email : emailUrl.get("email")!,
                senha: 'senha',
                confirmacaoSenha: '',
                tokenNovaSenha: ''
            };

            const retornoAPI = await EnviarEsqueciMinhaSenha(usuario);

            if (!retornoAPI.erro) {
                setExibirMensagem(true);
                configurarMensagem(retornoAPI.mensagem!);
                return;
            }

            throw new Error(retornoAPI.mensagem);

        } catch (error) {
            configurarErro((error as Error).message);
        }
    }

    return (

        <section id="content-wrapper" className="esqueci-senha">
            <div className="content-form flex">
                <div className="content-form-content">
                    <form
                        onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <h1>
                                Recuperação de Senha
                            </h1>
                            <p>Informe seu e-mail para recuperar sua senha.</p>
                        </div>

                        <div className="mb-3">
                            <input
                                style={{ width: "100%" }}
                                className="form-control"
                                id="email"
                                type="email"
                                placeholder="Digite Seu E-mail"
                                required
                                value={email ? email : emailUrl.get("email")!}
                                onChange={emailInput}
                            />
                        </div>

                        <div className="button-wrapper mb-4">
                            <Link to="/" className='hover:underline' style={{ textDecoration: "none" }}> Voltar </Link>
                            <button
                                className="btn btn-primary btn-lg"
                                type="submit">
                                Enviar
                            </button>
                        </div>
                        {msgErro.length > 0 && <div className="mt-2 mb-1 text-[#f00] font-bold w-[450px]">{msgErro}</div>}

                        {/* <div className="clearfix"></div>
                        <div className="mb-2" style={{ marginTop: "100px" }} >
                            <div style={{ marginLeft: "35%" }}>
                                <LogoTipoNLA />
                            </div>
                        </div> */}
                    </form>
                </div>
                <div className="footer">
                    <a href="#">Suporte</a> <a href="#">Politica de Privacidade</a> <a href="#">Termo de Uso</a>
                </div>
            </div>

            <Modal show={exibirMensagem} onHide={fecharModal}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: 14 }}>Mensagem</Modal.Title>
                </Modal.Header>
                <Modal.Body>{mensagemRetornoAPI}</Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={fecharModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}