import { ChangeEvent, SetStateAction } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IUsuario } from "../entidades/retorno-api";

interface IFiltroAvaliador {
    ObterNomeEmail : (event:ChangeEvent<HTMLInputElement>) => void,
    ObterStatus : (event:ChangeEvent<HTMLSelectElement>) => void,
    FiltrarUsuarios : () => Promise<void>,
    configurarVisibilidadeDashBoarInicial : React.Dispatch<SetStateAction<boolean>>,
    configurarListaUsuario : React.Dispatch<SetStateAction<Array<IUsuario>|undefined>>;
}

export default function FiltroAvaliador({ObterNomeEmail,ObterStatus,FiltrarUsuarios,configurarVisibilidadeDashBoarInicial,configurarListaUsuario}:IFiltroAvaliador){
     
     const MostrarDashBoard = ():void => {
        configurarListaUsuario([]);
        configurarVisibilidadeDashBoarInicial(true);
        document.body.style.background = "none";
     }

    return(
        <>
             <Row className='text-[white] mt-3'>
                    <Col>
                    
                        <input type='text' className='form-control' placeholder="Filtrar Usuários Por Nome ou Email" style={{ borderWidth:"2px" , borderColor:"#bbb" , fontWeight:"bold"}}
                            onChange={ObterNomeEmail} />
                    </Col>
                    <Col md={3}>
                    
                        <Form.Select onChange={ObterStatus} style={{ borderWidth:"2px" , borderColor:"#bbb", fontWeight:"bold"}}>
                            <option value={''}> Todos Status</option>
                            <option value={'INICIADO'}> Iniciado </option>
                            <option value={'CONCLUIDO'}> Concluído</option>
                            <option value={'PENDENTEAVALIADOR'}> Pendente Avaliador </option>
                            <option value={'PENDENTEUSUARIO'}> Pendente Usuário</option>
                        </Form.Select>
                    </Col>
                    <Col md={3}>
                        
                        <Button className="font-sans shadow-xl hover:shadow-md"
                            onClick={FiltrarUsuarios}
                            type="button" style={{background:"#684EA0",border:"none"}}>
                            <i className='fa fa-search'></i> Buscar
                        </Button>
                        {' '}
                        <Button className="font-sans shadow-xl hover:shadow-md" 
                        style={{borderRadius:"50%",background:"#684EA0", border:"none"}}
                            onClick={MostrarDashBoard}
                            type="button"  >
                            <i className='fa fa-home'></i>
                        </Button>
                    </Col>
                </Row>
        </>
    )
}