import { Alert, Col, Container, Row } from "react-bootstrap";
import { ChangeEvent } from "react";
import { IQuestionario } from "../entidades/questionario";
import TabQuestionario from "./TabQuestionario";

interface IRespostasQuestionario{
    flagConcluido:boolean,
    EscolherNivel:(valor:ChangeEvent<HTMLSelectElement>) => Promise<void>,
    GravarTextoTextArea:(valor:React.FocusEvent<HTMLTextAreaElement>) => Promise<void>
    respostas:IQuestionario[]|undefined
}

export default function RespostasQuestionario({flagConcluido,EscolherNivel,
GravarTextoTextArea,respostas}:IRespostasQuestionario){

    const tabsRespostas = {
        SoftSkill: respostas?.filter(n => n.idGrupoQuestionario === 11),
        HardSkill: respostas?.filter(n => n.idGrupoQuestionario === 12),
        Funcional: respostas?.filter(n => n.idGrupoQuestionario === 10),
        Conclusao : ''
    }

    return(
        <>
            {respostas!.length === 0 ? (
                <>
                    <Container>
                        <Row className="mt-5">
                            <Col>
                                <Alert variant='danger' dismissible>Não há questões respondidas</Alert>
                            </Col>
                        </Row>
                    </Container>
                </> ) : (
                          <TabQuestionario 
                          EscolherNivel={EscolherNivel}
                           tabs={tabsRespostas}
                           GravarTextoTextArea={GravarTextoTextArea}
                           flagConcluido={flagConcluido}
                          />
                )
            }
    </>
    )
}