

import { ChangeEvent, FormEvent, Suspense, useEffect, useState } from "react";
import { Usuario } from "../../entidades/usuario";
import { Cadastrar } from "../../servicos/cadastrar";
import TelaLoginCadastro from "../../components/TelaLoginCadastro";
import { TipagemTelaLoginCadastro } from "../../tipos/tipos";
import { Link } from "react-router-dom";
import { ICadastroValidacao, CadastroValidacao } from "../../validacoes/CadastroValidacao";
import { Button, Modal } from "react-bootstrap";
import Header from "../../components/Header";


export default function Cadastro() {
  const [email, configuarEmail] = useState('');
  const [senha, configurarSenha] = useState('');
  const [confirmacaoSenha, configurarConfirmacaoSenha] = useState('');
  const [msgErro, configurarErro] = useState('');
  const [exibirMensagem, setExibirMensagem] = useState<boolean>(false);

  const fecharModal = () => document.location.href = "/";


  useEffect(() => {

    // document.body.style.backgroundColor = "#f7f7f7";
    // document.body.style.backgroundImage = 'url(/logo-imagem-esquerda.png)';
    // document.body.style.backgroundPosition='0px 0px';
    // document.body.style.backgroundSize='550px';
    // document.body.style.backgroundRepeat='no-repeat';

  }, []);


  const confirmacaoSenhaInput = (e: ChangeEvent<HTMLInputElement>) => {
    let valor = e.target.value;

    if (valor.length === 0) configurarErro("");

    configurarConfirmacaoSenha(e.target.value)
  }

  const handleSubmit = async (e: FormEvent) => {

    e.preventDefault();

    try {
      let cadastroValidacao: ICadastroValidacao = new CadastroValidacao();
      cadastroValidacao.validacaoGeral(senha, confirmacaoSenha);

      const usuario: Usuario = { email, senha, confirmacaoSenha };
      const retornoAPI = await Cadastrar(usuario);

      if (!retornoAPI.erro) {
        setExibirMensagem(true);
        return;
      }

      throw new Error(retornoAPI.mensagem);

    } catch (error) {
      configurarErro((error as Error).message);
    }
  }

  let placeHolderSenha = "Cadastre sua senha";
  let tituloTela = "Vamos começar";
  let subtitulo = "Use o formulário abaixo para criar uma conta.";

  const parametroGeraisTelaLogin: TipagemTelaLoginCadastro = {
    class: "cadastro",
    tituloTela,
    subtitulo,
    handleSubmit,
    email,
    senha,
    placeHolderSenha,
    setEmail: configuarEmail,
    setPassword: configurarSenha
  }

  return (
    <>

      <TelaLoginCadastro parametrosGerais={parametroGeraisTelaLogin}>

        <div className="mb-3">
          <input
            style={{ width: "100%" }}
            className="form-control"
            id="passwordConfirm"
            type="password"
            placeholder="Confirme sua Senha"
            required
            value={confirmacaoSenha}
            onChange={confirmacaoSenhaInput}
          />
        </div>
        <div className="button-wrapper mb-4">
          <span></span>
          <button
            className="btn btn-primary btn-lg"
            type="submit"
          >
            Criar conta
          </button>
        </div>

        {msgErro.length > 0 && <div className="mt-2 mb-1 text-[#f00] font-bold w-[450px]" style={{ fontSize: "12px" }}>{msgErro}</div>}

        <div className="mb-2 mt-2">
          <label className="block text-base font-sans text-left ">
            Já possui conta? <Link to="/" className='hover:underline' style={{ textDecoration: "none" }}> Entrar </Link>
          </label>
        </div>

      </TelaLoginCadastro>

      <Modal show={exibirMensagem} onHide={fecharModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 14 }}>Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>Cadastro feito com sucesso, agora você pode entrar no sistema.</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={fecharModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}
