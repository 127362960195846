
import { IRetornoAPI } from '../entidades/retorno-api';


export const useApi = () => ({
    
    validateToken: async (token: string):Promise<boolean> => {
    
        const response = await fetch(`${process.env.REACT_APP_API!}/usuario/validartoken/`,
        { 
         body : JSON.stringify({ valor : token }) , 
         method: "POST",
         headers : { "Content-Type" : "application/json"} 
        });

        const retorno = await response.json() as IRetornoAPI;
        console.log(retorno);
        return retorno.erro;   
    },
    signin: async (email: string, password: string):Promise<IRetornoAPI> => {
        
        const response = await fetch(`${process.env.REACT_APP_API!}/usuario/autenticar/`,
        { 
         body : JSON.stringify({ email, senha: password }) , 
         method: "POST",
         headers : { "Content-Type" : "application/json"} 
        });

        return await response.json() as IRetornoAPI;     
    }
});