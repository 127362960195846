
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { AuthContext } from "./AuthContext";
import { IRetornoAPI, IUsuario } from "../../entidades/retorno-api";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const [user, setUser] = useState<IUsuario | null>(null);
    const api = useApi();
    const email:string = '';

    useEffect( () => {
        
        const validateToken = async () => {
            
            const tokenArmazenado = localStorage.getItem('authToken');
           
            if (tokenArmazenado !== "") {
                const retornoEstaInvalido = await api.validateToken(tokenArmazenado!);
   
                if (retornoEstaInvalido){
                    localStorage.removeItem('authToken'); 
                    setUser(null);
                    return;  
                }

                const usuarioGuardado = JSON.parse(localStorage.getItem('usuario')!) as IUsuario;
                setUser(usuarioGuardado);
            }
        }

        validateToken();

    },[]);

    const signin = async (email: string, password: string):Promise<IRetornoAPI> => {
        
        const data = await api.signin(email, password);

        if (!data.erro){
            setToken(data.token!);
            setUser(data.usuario!);
            guardarUsuario(data.usuario!);
        }

        return data;
    }

    const setToken = (token: string) => localStorage.setItem('authToken', token);
    const guardarUsuario = (usuario:IUsuario) => localStorage.setItem('usuario', JSON.stringify(usuario));
    
    return (
        <AuthContext.Provider value={{ user, signin, email }}>
            {children}
        </AuthContext.Provider>
    );
}