import LogoLevel from "./LogoLevel";
import { Col, Row } from "react-bootstrap";

export default function FooterSistema(){
   
    return(
    <footer>
        {/* <Row style={{background:"#684EA0",color:"white" , position:"fixed" , width:"100%" , margin:0, bottom:0}}>
            <Col className="text-right mt-1" style={{fontSize:"20px"}}><strong>Conheça </strong> </Col>
            <Col><LogoLevel></LogoLevel></Col>
        </Row> */}
           
    </footer>
    );
}