import { Col, Row, Tab, Tabs } from "react-bootstrap";
import QuestionarioDropDownTextArea from "./QuestionarioDropDownTextArea";
import { IQuestionario } from "../entidades/questionario";
import { ChangeEvent } from "react";

interface ITabQuestionario {
    tabs: {
        SoftSkill: IQuestionario[] | undefined,
        HardSkill: IQuestionario[] | undefined,
        Funcional: IQuestionario[] | undefined
    },
    EscolherNivel: (valor: ChangeEvent<HTMLSelectElement>) => Promise<void>,
    flagConcluido: boolean,
    GravarTextoTextArea: (valor: React.FocusEvent<HTMLTextAreaElement>) => Promise<void>
}

export default function TabQuestionario({ tabs, EscolherNivel, GravarTextoTextArea,
    flagConcluido }: ITabQuestionario) {

    return (
        <>
            <Row className='mt-2'>
                <Col md={12}>
                    <Tabs defaultActiveKey={Object.keys(tabs)[0]}
                        id="tabsCompetencia"
                        className='navegar'>
                        <Tab className="pt-3" eventKey={Object.keys(tabs)[0]} title={Object.keys(tabs)[0] + ` (${tabs.SoftSkill?.length})`}>
                            <QuestionarioDropDownTextArea
                                EscolherNivel={EscolherNivel}
                                desabilitarDropDown={flagConcluido}
                                lista={tabs.SoftSkill!}
                                GravarTextarea={GravarTextoTextArea}
                                key={Object.keys(tabs)[0]}
                                tipoLista="SoftSkill"
                            />
                        </Tab>
                        <Tab className="pt-3" eventKey={Object.keys(tabs)[1]} title={Object.keys(tabs)[1] + ` (${tabs.HardSkill?.length})`}>
                            <QuestionarioDropDownTextArea
                                EscolherNivel={EscolherNivel}
                                desabilitarDropDown={flagConcluido}
                                lista={tabs.HardSkill!}
                                GravarTextarea={GravarTextoTextArea}
                                key={Object.keys(tabs)[1]}
                                tipoLista="HardSkill"
                            />
                        </Tab>
                        <Tab className="pt-3" eventKey={Object.keys(tabs)[2]} title={Object.keys(tabs)[2] + ` (${tabs.Funcional?.length})`}>
                            <QuestionarioDropDownTextArea
                                EscolherNivel={EscolherNivel}
                                desabilitarDropDown={flagConcluido}
                                lista={tabs.Funcional!}
                                GravarTextarea={GravarTextoTextArea}
                                key={Object.keys(tabs)[2]}
                                tipoLista="Funcional"
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
}