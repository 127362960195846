import { IRetornoAPI } from "../entidades/retorno-api";
import { Usuario } from "../entidades/usuario";

export async function EnviarEsqueciMinhaSenha(usuario:Usuario) {
    
    const apiCadastro = await fetch(`${process.env.REACT_APP_API!}/usuario/esqueciminhasenha`,{
        method: "POST",
        body: JSON.stringify(usuario),
        headers :{ "Content-Type":"application/json" }
    });
    
    const retornoAPI = await apiCadastro.json() as IRetornoAPI;

    return retornoAPI;
}
