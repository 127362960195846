import { Link } from "react-router-dom";

export default function PaginaNaoEncontrada(){

return(
    <>
        <div style={{minHeight:'100vh',display:'flex', alignItems:'center', justifyContent:'center'}}>
            <h1>404 - Página não encontrada - <Link to="/" style={{color:'black'}}>Ir Para Página Inicial</Link></h1>
        </div>
    </>
);

}