import { IQuestionario } from "../entidades/questionario";

export async function ObterRespostaProcurementAssessment(email:string) : Promise<Array<IQuestionario>> {
    const token = `Bearer ${localStorage.getItem('authToken')}`;
    const apiProcurementAssessment = await fetch(`${process.env.REACT_APP_API!}/questionario/procurement-assessment?email=${email}`,
    {
        method:"GET",
        headers: { "Authorization" : token! }
    });
    const retornoAPI = await apiProcurementAssessment.json() as Array<IQuestionario>;
    return retornoAPI;
}