import React, { Dispatch, useReducer, useState } from "react"
import { Alert, Button, Modal } from "react-bootstrap"
import { CadastroValidacao, ICadastroValidacao } from "../validacoes/CadastroValidacao";
import { AlterarDados } from "../servicos/AlteracaoDados";
import { IAlteracaoDados } from "../servicos/interfaces/alterardadosservico";
import { IUsuario } from "../entidades/retorno-api";

interface ITipoModal {
    fecharModalAlteracao:boolean,
    configurarVisibilidadeModalAlteracao : Dispatch<React.SetStateAction<boolean>>
}

interface ICamposAlteracao {
    Nome:string,
    SenhaAtual:string,
    NovaSenha:string,
    ConfirmarNovaSenha:string
}

const campoInicial:ICamposAlteracao = {
    Nome:'' , SenhaAtual:'',NovaSenha:'',ConfirmarNovaSenha:''
};

enum TipoAcaoEnum {
    ATUALIZARNOME,
    ATUALIZARSENHAATUAL,
    ATUALIZARNOVASENHA,
    ATUALIZARCONFIRMARSENHA,
    LIMPARCAMPOS
}  

interface ITipoReducer {
    tipo:TipoAcaoEnum,
    payload:Partial<ICamposAlteracao>
}

function Reducer(campo:ICamposAlteracao,acao:ITipoReducer) : ICamposAlteracao {

    switch(acao.tipo){
        case TipoAcaoEnum.ATUALIZARNOME:
            return {...campo, Nome: acao.payload.Nome!  };
        case TipoAcaoEnum.ATUALIZARSENHAATUAL:
            return {...campo, SenhaAtual : acao.payload.SenhaAtual!  };
        case TipoAcaoEnum.ATUALIZARNOVASENHA:
            return {...campo, NovaSenha : acao.payload.NovaSenha! };
        case TipoAcaoEnum.ATUALIZARCONFIRMARSENHA:
            return {...campo, ConfirmarNovaSenha : acao.payload.ConfirmarNovaSenha!  };
        default:
            return campoInicial;    
    }
}



export default function ModalAlteracaoDados({TipoModal } : {TipoModal:ITipoModal} ){
    const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
    const [campos,dispatch] = useReducer(Reducer,campoInicial);
    const cadastroValidacao:ICadastroValidacao = new CadastroValidacao();
    const [erro,configurarErro] = useState<string>('');
    const [sucesso,configurarAlteracao] = useState<string>('');
    
    const ConfirmarAlteracao = async ():Promise<void> => {
        try{
            const alterarDados: IAlteracaoDados = {
                Email: usuario.email!,
                Nome: campos.Nome,
                NovaSenha: campos.NovaSenha,
                Senha: campos.SenhaAtual
            }

            if(alterarDados.Nome === '' && alterarDados.Senha === '' && alterarDados.NovaSenha === '')
                throw new Error('Para alteração acontecer, deve-se preencher no minimo, o nome ou grupo de campos de senha');
            
            VerificarSenhaAtualVaziaComNovaOuConfirmacaoPreenchida(alterarDados);
            VerificarSenhaAtualPreenchidaComNovaOuConfirmacaoVazia(alterarDados);    
            
            if (campos.SenhaAtual !== '' && campos.NovaSenha !== '' && campos.ConfirmarNovaSenha !== '')
                cadastroValidacao.validacaoGeral(campos.NovaSenha, campos.ConfirmarNovaSenha);
          
            const retorno = await AlterarDados(alterarDados);

            if(retorno.erro)
                throw new Error(retorno.mensagem);
            
            configurarErro('');
            configurarAlteracao(retorno.mensagem!);

        }catch(erro){
            configurarErro((erro as Error).message);
        }

        function VerificarSenhaAtualVaziaComNovaOuConfirmacaoPreenchida (alterarDados: IAlteracaoDados) {
            if (alterarDados.Senha === '' && (alterarDados.NovaSenha !== '' || campos.ConfirmarNovaSenha !== ''))
                throw new Error('Para alteração de senha, os campos: senha atual, nova senha e confirmar senha devem ser preenchidos');
        }

        function VerificarSenhaAtualPreenchidaComNovaOuConfirmacaoVazia(alterarDados: IAlteracaoDados) {
            if (alterarDados.Senha !== '' && (alterarDados.NovaSenha === '' || campos.ConfirmarNovaSenha === ''))
                throw new Error('Para alteração de senha, os campos: senha atual, nova senha e confirmar senha devem ser preenchidos');
        }
    }

    const Cancelar = () => {
        configurarErro('');
        dispatch({ tipo : TipoAcaoEnum.LIMPARCAMPOS , payload : campoInicial });
        TipoModal.configurarVisibilidadeModalAlteracao(false);
    }

    const mensagens = {
        erro : <Alert variant="danger" dismissible> {erro}</Alert> ,
        sucesso :  <Alert variant="success" dismissible> {sucesso}</Alert> 
    }
    
    return(
        <Modal show={TipoModal.fecharModalAlteracao} onHide={Cancelar}>
        <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 20 , color:"#684EA0" }}>Alteração de dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label>Nome Exibição</label>
            <input type="text" className="form-control" style={{borderRadius:"20px"}} onChange={(e) => dispatch({tipo : TipoAcaoEnum.ATUALIZARNOME , payload : { Nome : e.target.value } })} /> 
            <hr />
            <label>Senha Atual</label>
            <input type="password" className="form-control" style={{borderRadius:"20px"}} onChange={(e) => dispatch({tipo : TipoAcaoEnum.ATUALIZARSENHAATUAL , payload : { SenhaAtual : e.target.value } })} />
            <hr />
            <label>Nova Senha</label>
            <input type="password" className="form-control" style={{borderRadius:"20px"}} onChange={(e) => dispatch({tipo : TipoAcaoEnum.ATUALIZARNOVASENHA , payload : { NovaSenha : e.target.value } })} />
            <hr />
            <label>Confirmar Senha</label>
            <input type="password" className="form-control" style={{borderRadius:"20px"}} onChange={(e) => dispatch({tipo : TipoAcaoEnum.ATUALIZARCONFIRMARSENHA , payload : { ConfirmarNovaSenha : e.target.value } })} />
            <hr />
            {erro && mensagens['erro']}
            {sucesso && mensagens['sucesso']}
            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={Cancelar}>
                Cancelar
            </Button>
            <Button variant="success" onClick={ConfirmarAlteracao}>
                Alterar
            </Button>
        </Modal.Footer>
    </Modal>
    )
}