import { Col, ProgressBar, Row, Table } from "react-bootstrap";
import { IQuestionario } from "../entidades/questionario";
import { IUsuario } from "../entidades/retorno-api";
import { color } from "html2canvas/dist/types/css/types/color";


interface IRelatorioFinal {
    questionario: IQuestionario[],
    usuarioNomeEmail?: { nomeUsuario: string, emailUsuario: string },
    tabs?:{
        SoftSkill: Array<IQuestionario>,
        HardSkill: Array<IQuestionario>,
        Funcional:Array<IQuestionario> 
    }
}

export default function RelatorioFinal({ questionario, usuarioNomeEmail,tabs }: IRelatorioFinal) {
    
    const usuario = JSON.parse(localStorage.getItem("usuario")!) as IUsuario;
   const exibirNivelAvaliador = usuario.flagAvaliador || usuario.emailAvaliador !== "";

    return (
        <>
            <br />
            <div id="relatorio-final" style={{ fontFamily: "sans-serif", paddingBottom: 20 }}>
                {questionario && <h4><strong>{usuarioNomeEmail?.nomeUsuario} ({usuarioNomeEmail?.emailUsuario})</strong></h4>}
                <br />
                <h4 className="text-left"><strong style={{background:"#52C0A4" , fontWeight:"bold" , color:"#fff", padding:"10px"}} >MAPEAMENTO DE HABILIDADES</strong></h4>
                <br />
                <h5><strong style={{color:"#684EA0"}}>OBJETIVO</strong></h5>
                <div> Este assessment visa mapear as skills dos usuários e desta forma identificar gaps que possam ser preenchidos por treinamentos e capacitações visando sempre o crescimento profissional do envolvido seguindo algumas etapas: </div>
                <br />

                <Row>
                <div className="text-center" style={{background:"#684EA0",borderRadius:"50%",height:"50px",width:"50px", padding:"10px",zIndex:"1" , color:"white"}}><strong style={{fontSize:"20px"}}>1</strong></div>
                <span style={{position:"relative",background:"#52C0A4",color:"white",fontWeight:"bold",fontSize:"20px",top:"-40px",left:"45px"}}>Parametrizar através de Benchmark o perfil do profissional.</span>
                <div className="text-center" style={{background:"#684EA0",borderRadius:"50%",height:"50px",width:"50px", padding:"10px",zIndex:"1" , color:"white"}}><strong style={{fontSize:"20px"}}>2</strong></div>
                <span style={{position:"relative",background:"#52C0A4",color:"white",fontWeight:"bold",fontSize:"20px",top:"-40px",left:"45px"}}>Definir as principais competências deste "profissional ideal", considerando as melhores práticas de mercado.</span>
                <div className="text-center" style={{background:"#684EA0",borderRadius:"50%",height:"50px",width:"50px", padding:"10px",zIndex:"1" , color:"white"}}><strong style={{fontSize:"20px"}}>3</strong></div>
                <span style={{position:"relative",background:"#52C0A4",color:"white",fontWeight:"bold",fontSize:"20px",top:"-40px",left:"45px"}}>Indicação de Treinamentos afim de suportar o crescimento deste profissional.</span>
                
                </Row>

                <hr />
                <h5><strong style={{color:"#684EA0"}}>METODOLOGIA</strong></h5>
                <div>
                    De acordo com os benchmarks realizados, foram definidas algumas skills e competências para podermos identificar e equalizar estes profissionais de uma forma que possamos ter a visibilidade real e efetiva das suas capacidades para direcionamento assertivo de capacitação.
                    <br />
                    Para isso separamos em 03 blocos de skills, sendo eles: <br /><br />
                    <Row>
                        <Col className="font-sans text-center" style={{background:"#52C0A4" , fontWeight:"bold", color:"#fff" , borderRadius:"20px" ,fontSize:"20px" }}>Soft Skill</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="font-sans text-center" style={{background:"#52C0A4" , fontWeight:"bold", color:"#fff" , borderRadius:"20px" ,fontSize:"20px" }}>Hard Skill</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="font-sans text-center" style={{background:"#52C0A4" , fontWeight:"bold", color:"#fff" , borderRadius:"20px" ,fontSize:"20px" }}>Funcional</Col>
                        
                    </Row>
                     
                    <Row className="mt-2">

                    </Row>

                    {/* <Row>
                        <Col style={{background:"#52C0A4" , fontWeight:"bold" , color:"#fff"}}>SOFT SKILL</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#52C0A4" , fontWeight:"bold" , color:"#fff"}}>HARD SKILL</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#52C0A4", fontWeight:"bold" , color:"#fff"}}>FUNCIONAL</Col>
                    </Row> */}
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Flexibilidade</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Análise Competitiva</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Contratos</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Habilidade de Influenciar</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Análise de Mercado</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Envolvimento em Projetos</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Objetividade</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Referência</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Ferramentas de Apresentação</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Mente Aberta</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Estrutura de Custo</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Ferramenta de Gestão</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Poder Analítico</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Gestão de Projetos</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Ferramenta de Negociação</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Proatividade</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Gestão de Processos</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Gestão</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Trabalho em Equipe</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Gestão de Riscos</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Negociação</Col>
                    </Row>
                    <Row className="mt-2 font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Integração Mercado Local</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE" , color:"#000"}}>Pedidos de Compra</Col>
                    </Row>

                    <Row className="font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Faça ou Compre</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Sistema ERP</Col>
                    </Row>

                    <Row className="font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Planejamento/Gestão Demanda</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Abastecimento</Col>
                    </Row>

                    <Row className="font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Orientação de Mercado</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE"}}></Col>
                    </Row>

                    <Row className="font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>Sourcing Colaborativo</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE"}}></Col>
                    </Row>

                    <Row className="font-sans">
                        <Col style={{background:"#DDDEDE"}}></Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col className="mt-2" style={{background:"#DDDEDE" , color:"#000"}}>TCO</Col>
                        <Col md={1} style={{width:"5px"}}></Col>
                        <Col style={{background:"#DDDEDE"}}></Col>
                    </Row>
                   
                    <hr />
                    <div style={{background:"#52C0A4", padding:"20px" , color:"#fff", width:"300px" }}><strong>SOFT SKILL</strong></div>
                    {tabs && tabs.SoftSkill.map((SoftSkill, indice) =>
                        <div key={indice} className="mt-4 p-2 box-shadow" style={{ background:"#fff", borderRadius: 20 }}>
                            <h5><strong style={{borderBottom:"solid 3px #52C0A4"}}>{SoftSkill.competencia}</strong></h5>
                            <p style={{ fontFamily: "sans-serif" }}>{SoftSkill.questao}</p>
                            <Row title="Nivel Escolhido Pelo Usuário">
                                <Col md={2}>Usuário:</Col>
                                <Col className="mt-1"><ProgressBar style={{ width: 200 }} variant="success" now={SoftSkill.nivelEscolhido! * 20} label={SoftSkill.nivelEscolhido} /></Col>
                            </Row>

                            {exibirNivelAvaliador &&
                                <Row title="Nivel Escolhido Pelo Avaliador">
                                    <Col md={2}>Avaliador: </Col>
                                    <Col className="mt-1"><ProgressBar variant="primary" style={{ width: 200 }} now={SoftSkill.nivelEscolhidoAvaliador! * 20} label={SoftSkill.nivelEscolhidoAvaliador} /></Col>
                                </Row>
                            }
                            <Row title="Nivel Escolhido Pelo Mercado">
                                <Col md={2}>Benchmark: </Col>
                                <Col className="mt-1"><ProgressBar variant="danger" style={{ width: 200 }} now={SoftSkill.nivelBenchMark! * 20} label={SoftSkill.nivelBenchMark} /></Col>
                            </Row>
                        </div>
                    )}
                    <hr />
                    <div style={{background:"#52C0A4", padding:"20px" , color:"#fff", width:"300px" }}><strong>HARD SKILL</strong></div>
                    {tabs && tabs.HardSkill.map((HardSkill, indice) =>
                        <div key={indice} className="mt-4 p-2 box-shadow " style={{ background:"#fff", borderRadius: 20 }}>
                            <h5><strong style={{borderBottom:"solid 3px #52C0A4"}}>{HardSkill.competencia}</strong></h5>
                            <p style={{ fontFamily: "sans-serif" }}>{HardSkill.questao}</p>
                            <Row title="Nivel Escolhido Pelo Usuário">
                                <Col md={2}>Usuário:</Col>
                                <Col className="mt-1"><ProgressBar variant="success" style={{ width: 200 }} now={HardSkill.nivelEscolhido! * 20} label={HardSkill.nivelEscolhido} /></Col>
                            </Row>
                            {exibirNivelAvaliador &&
                                <Row title="Nivel Escolhido Pelo Avaliador">
                                    <Col md={2}>Avaliador: </Col>
                                    <Col className="mt-1"><ProgressBar variant="primary" style={{ width: 200 }} now={HardSkill.nivelEscolhidoAvaliador! * 20} label={HardSkill.nivelEscolhidoAvaliador} /></Col>
                                </Row>
                            }
                            <Row title="Nivel Escolhido Pelo Mercado">
                                <Col md={2}>BenchMark: </Col>
                                <Col className="mt-1"><ProgressBar variant="danger" style={{ width: 200 }} now={HardSkill.nivelBenchMark! * 20} label={HardSkill.nivelBenchMark} /></Col>
                            </Row>
                        </div>
                    )}
                    <hr />
                    <div style={{background:"#52C0A4", padding:"20px" , color:"#fff", width:"300px" }}><strong>FUNCIONAL</strong></div>
                    {tabs && tabs.Funcional.map((Funcional, indice) =>
                        <div key={indice} className="mt-4 p-2 box-shadow " style={{ background:"#fff", borderRadius: 20 }}>
                            <h5><strong style={{borderBottom:"solid 3px #52C0A4"}}>{Funcional.competencia}</strong></h5>
                            <p style={{ fontFamily: "sans-serif" }}>{Funcional.questao}</p>
                            <Row title="Nivel Escolhido Pelo Usuário">
                                <Col md={2}>Usuário:</Col>
                                <Col className="mt-1"><ProgressBar variant="success" style={{ width: 200 }} now={Funcional.nivelEscolhido! * 20} label={Funcional.nivelEscolhido} /></Col>
                            </Row>
                            {exibirNivelAvaliador &&
                                <Row title="Nivel Escolhido Pelo Avaliador">
                                    <Col md={2}>Avaliador: </Col>
                                    <Col className="mt-1"><ProgressBar variant="primary" style={{ width: 200 }} now={Funcional.nivelEscolhidoAvaliador! * 20} label={Funcional.nivelEscolhidoAvaliador} /></Col>
                                </Row>
                            }
                            <Row title="Nivel Escolhido Pelo Mercado">
                                <Col md={2}>BenchMark: </Col>
                                <Col className="mt-1"><ProgressBar variant="danger" style={{ width: 200 }} now={Funcional.nivelBenchMark! * 20} label={Funcional.nivelBenchMark} /></Col>
                            </Row>
                        </div>
                    )}
                    <hr />
                </div>
            </div>
        </>
    )
}