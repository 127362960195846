import { Col, Row } from "react-bootstrap";
import { TipagemTelaLoginCadastro } from "../tipos/tipos";
import LogoTipoNLA from "./LogoTipoNLA";

export default function TelaLoginCadastro({ parametrosGerais, children }:
    { parametrosGerais: TipagemTelaLoginCadastro, children: React.ReactNode }) {

    return (
        <section id="content-wrapper" className={parametrosGerais.class}>
            <div className="content-form flex">
                <div className="content-form-content">
                    <form
                        onSubmit={parametrosGerais.handleSubmit}>
                        <div className="mb-4">
                            <h1 className="block text-[26px] mb-2">
                                {parametrosGerais.tituloTela}
                            </h1>
                            <p>{parametrosGerais.subtitulo}</p>
                        </div>

                        <div className="mb-3">
                            <input
                                style={{ width: "100%" }}
                                className="form-control mt-1"
                                id="email"
                                type="email"
                                placeholder="Seu E-mail"
                                required
                                value={parametrosGerais.email}
                                onChange={(e) => parametrosGerais.setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                style={{ width: "100%" }}
                                className="form-control mt-1"
                                id="password"
                                type="password"
                                placeholder="Sua Senha"
                                required
                                value={parametrosGerais.senha}
                                onChange={(e) => parametrosGerais.setPassword(e.target.value)}
                            />
                        </div>

                        {children}

                        {/* <div className="mb-2" style={{ marginTop: "100px" }} >
                        <div style={{ marginLeft: "25%" }}>
                            <LogoTipoNLA />
                        </div>
                    </div> */}
                    </form>
                </div>
                <div className="footer">
                    {/* <a href="#">Suporte</a> <a href="#">Politica de Privacidade</a> <a href="#">Termo de Uso</a> */}
                </div>
            </div>
        </section>
    );

}