import './index.css'
import { useEffect, useState } from 'react';
import HeaderSistema from '../../components/HeaderSistema';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { IUsuario } from '../../entidades/retorno-api';
import { CarregarDashBoardInicial, ObterUsuariosPorAvaliadorFiltro } from '../../servicos/avaliador';
import { IDashBoardInicial } from '../../entidades/dashboardinicial';
import { IFiltroAvaliador } from '../../servicos/interfaces/avaliadorservico';
import FiltroAvaliador from '../../components/FiltroAvaliador';
import { Link, useNavigate } from 'react-router-dom';
import FooterSistema from '../../components/FooterSistema';
import { Configuracao } from '../../configuracoes/Configuracao';


export default function Avaliador() {
    const [mostrarDashBoardInicial, configurarVisibilidadeDashBoarInicial] = useState<boolean>(false);
    const [listaUsuarios, configurarListaUsuario] = useState<Array<IUsuario>>();
    const [dashboad, configurarDashBoard] = useState<Array<IDashBoardInicial>>();
    const [usuariosNaoEncontrados, configurarUsuariosNaoEncontrados] = useState<boolean>(false);
    const [filtro, configurarFiltro] = useState<IFiltroAvaliador>({});
    const redicionar = useNavigate();
    const [token,setToken] = useState<string>(null!);
    const [mostrarFormaAvaliacao,configurarFormaAvaliacao] = useState<boolean>(false);

    const ObterNomeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        configurarFiltro({ ...filtro, nomeEmail: e.target.value });
    }

    const ObterStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
        configurarFiltro({ ...filtro, status: e.target.value });
    }

    const FiltrarUsuarios = async (filtro: IFiltroAvaliador) => {

        configurarVisibilidadeDashBoarInicial(false);

        try {

            if(filtro.status === "PENDENTEAVALIADOR" && dashboad![2]?.total > 0){
                configurarFormaAvaliacao(true);
                configurarUsuariosNaoEncontrados(false);
                configurarListaUsuario([]);
                return;
            }

            configurarFormaAvaliacao(false);
            const usuariosFiltrados = await ObterUsuariosPorAvaliadorFiltro(filtro);
            console.log(usuariosFiltrados);
            configurarListaUsuario(usuariosFiltrados);
            configurarUsuariosNaoEncontrados(false);
        } catch (erro) {
            configurarUsuariosNaoEncontrados(true);
        }
    }


    const FiltrarAvaliacaoUnica = async (filtro: IFiltroAvaliador) => {
        configurarVisibilidadeDashBoarInicial(false);

        try {
            configurarFormaAvaliacao(false);
            const usuariosFiltrados = await ObterUsuariosPorAvaliadorFiltro(filtro);
            configurarListaUsuario(usuariosFiltrados);
            configurarUsuariosNaoEncontrados(false);
        } catch (erro) {
            configurarUsuariosNaoEncontrados(true);
        }
    }

    useEffect(() => {

        setToken(localStorage.getItem("authToken")!);
        
        document.body.style.background = "none";

        const DashBoardInicial = async () => {
            try {
                configurarDashBoard(await CarregarDashBoardInicial());
                configurarVisibilidadeDashBoarInicial(true);
                configurarUsuariosNaoEncontrados(false);
            } catch (erro) {
                configurarUsuariosNaoEncontrados(true);
            }
        }

        DashBoardInicial();
    }, []);

    const FiltrarPendenciaAvaliador = (nomeUsuario: string, emailUsuario: string): void => {
        localStorage.setItem("usuarioquestionario", JSON.stringify({ nomeUsuario, emailUsuario }));
        redicionar("/avaliador-questionario");
    }

    const filtroCard = [
        'INICIADO',
        'CONCLUIDO',
        'PENDENTEAVALIADOR',
        'PENDENTEUSUARIO'
    ];

    const paginaHome = () => {
        document.body.style.background = "none";
        document.location.href = "/avaliador";
        return;
    }

    return (
        <>
            <HeaderSistema home={paginaHome} />
            <Container className='mb-10'>
                <FiltroAvaliador FiltrarUsuarios={() => FiltrarUsuarios(filtro)}
                    ObterNomeEmail={ObterNomeEmail} ObterStatus={ObterStatus}
                    configurarListaUsuario={configurarListaUsuario}
                    configurarVisibilidadeDashBoarInicial={configurarVisibilidadeDashBoarInicial}
                />

                <Row className='mt-5' style={{borderRadius:"20px" , backgroundColor:"#eee" , opacity:"90%" , padding:"20px"}}>

                {mostrarDashBoardInicial && <>
                <Col md={3}  className='mt-3'>
                    <Card className='box-shadown' style={{ backgroundColor: "black", cursor: "pointer" , border:"none" , borderRadius:"10px"}}
                                onClick={() => FiltrarUsuarios({ nomeEmail: filtro.nomeEmail, status: filtroCard[0] })}>
                    <Card.Header style={{ background: "black", color: "white" }} className="text-center"><strong>Iniciado</strong></Card.Header>
                    <Card.Body style={{background:"#ffffff"}} className='text-center'>
                                    <h1 style={{color:"#684EA0"}}>{dashboad![0]?.total}</h1>
                    </Card.Body>
                    <Card.Footer style={{ background: "black", color: "white" }} className="text-center"><strong>Gerenciar</strong> <i className='fa fa-plus float-end mt-1'></i></Card.Footer>                
                    </Card>
                </Col>

                <Col md={3}  className='mt-3'>
                    <Card className='box-shadown' style={{ backgroundColor: "black", cursor: "pointer" , border:"none" , borderRadius:"10px"}}
                                onClick={() => FiltrarUsuarios({ nomeEmail: filtro.nomeEmail, status: filtroCard[1] })}>
                    <Card.Header style={{  background: "#52C0A4", color: "white" }} className="text-center"><strong>Concluido</strong></Card.Header>
                    <Card.Body style={{background:"#ffffff"}} className='text-center'>
                                    <h1 style={{color:"#684EA0"}}>{dashboad![1]?.total}</h1>
                    </Card.Body>
                    <Card.Footer style={{ background: "#52C0A4", color: "white" }} className="text-center"><strong>Gerenciar</strong> <i className='fa fa-plus float-end mt-1'></i></Card.Footer>
                    </Card>
                </Col>

                <Col md={3}  className='mt-3'>
                    <Card className='box-shadown' style={{ backgroundColor: "black", cursor: "pointer" , border:"none" , borderRadius:"10px"}}
                                onClick={() => FiltrarUsuarios({ nomeEmail: filtro.nomeEmail, status: filtroCard[2] })}>
                    <Card.Header style={{ background: "#DFE343", color: "white" }} className="text-center"><strong>Pendente Avaliador</strong></Card.Header>
                    <Card.Body style={{background:"#ffffff"}} className='text-center'>
                                    <h1 style={{color:"#684EA0"}}>{dashboad![2]?.total}</h1>
                    </Card.Body>
                    <Card.Footer style={{ background: "#DFE343", color: "white" }} className="text-center"><strong>Gerenciar</strong> <i className='fa fa-plus float-end mt-1'></i></Card.Footer>
                    </Card>
                </Col>

                <Col md={3}  className='mt-3'>
                    <Card className='box-shadown' style={{ backgroundColor: "black", cursor: "pointer" , border:"none" , borderRadius:"10px"}}
                                onClick={() => FiltrarUsuarios({ nomeEmail: filtro.nomeEmail, status: filtroCard[3] })}>
                    <Card.Header style={{ background: "#C4485A", color: "white" }} className="text-center"><strong>Pedente</strong></Card.Header>
                    <Card.Body style={{background:"#ffffff"}} className='text-center'>
                                    <h1 style={{color:"#684EA0"}}>{dashboad![3]?.total}</h1>
                    </Card.Body>
                    <Card.Footer style={{ background: "#C4485A", color: "white" }} className="text-center"><strong >Gerenciar</strong> <i className='fa fa-plus float-end mt-1'></i></Card.Footer>
                    </Card>
                </Col>
                <Row>
                        <Col></Col>
                            <Col md={3} key={dashboad?.length} className='mt-3'>
                            <Card className='box-shadown' style={{ backgroundColor: "", cursor: "pointer" , border:"none" , borderRadius:"10px"}}>        
                                <Card.Header style={{ background: "#52C0A4", color: "white" }} className="text-center"><strong>Total de Alunos </strong></Card.Header>
                                <Card.Body style={{background:"#ffffff"}} className='text-center'>
                                    <h1 style={{color:"#684EA0"}}>{dashboad?.reduce((x,y) => x + y.total,0)}</h1>
                                </Card.Body>
                               
                            </Card>
                        </Col>
                        <Col></Col>
                        </Row>
                    </>}
                    
                    {mostrarFormaAvaliacao && 
                    <>
                    <Col md={4} className='mt-3 mb-1 ml-5' style={{background:"#fff" , padding:"10px" , borderRadius:"20px"}}>
                       <strong style={{fontSize:"20px"}}>Formas de Avaliação</strong>
                       <hr style={{color:"#684EA0" , borderWidth:"2px" , borderColor:"#684EA0" , background:"#684EA0"}} />
                       <Link to="/avaliacao-lote" className='btn btn-info' onClick={() => false}>Avaliar Em Lote</Link>{' '}
                       <Button variant='warning' className='btn' onClick={() => FiltrarAvaliacaoUnica({status:filtroCard[2]})}>Avaliar Unitáriamente</Button>                                            
                    </Col>
                    
                       </>
                    }

                    { !mostrarDashBoardInicial && 
                       usuariosNaoEncontrados ? 
                     
                     <Alert variant='info' dismissible>Usuário(s) não encontrado(s)</Alert>
                        :
                        (
                            listaUsuarios?.map((usuario, indice) =>

                            <>
                             <Col md={4} key={indice} className='mt-3 mb-1 ml-5' style={{background:"#fff" , padding:"10px" , borderRadius:"20px"}}>
                                <strong style={{fontSize:"20px"}}>{usuario.nome}</strong>
                                <hr style={{color:"#684EA0" , borderWidth:"2px" , borderColor:"#684EA0" , background:"#684EA0"}} />
                                <label>E-mail: <u>{usuario.email}</u></label> <br />
                                <label>Status: {usuario.status}</label> <br />
                                {usuario.status === 'Pendente Avaliador' ?
                                                (<Button variant='info' className='float-end btn-sm' key={usuario.email} onClick={() => FiltrarPendenciaAvaliador(usuario.nome!, usuario.email!)}>Avaliar</Button>) :
                                                usuario.status === "Concluído" ? (
                                                    <>
                                                    <br />
                                                    <div className='text-right'>
                                                    <Link to={`/avaliador-questionario-detalhe?usuario=${usuario.email}`} className='btn btn-sm btn-info'> Detalhar </Link> {' '}
                                                    <a href={Configuracao.UrlRelatorioFinal.replace("#email#",usuario.email!).replace("#token#",token)} target='_blank' className='btn btn-success btn-sm' key={usuario.email}>Concluído</a>
                                                    </div>
                                                    </>
                                                ) :
                                                    (<Button variant='outline-secondary' className='float-end btn-sm' key={usuario.email}>Avaliar</Button>)
                                            }
                             </Col>
                             
                                </>)
                        )
                    }
                </Row>
            </Container>
            
            <FooterSistema />
        </>
    );
}