import { useContext, useEffect, useState } from "react";
import { Login } from "../../pages/Login";
import { AuthContext, AuthContextType } from "./AuthContext";

export default function RequireAuth ({ children }: { children: JSX.Element }) {
    const auth = useContext(AuthContext);
    
return(
    <>
    {auth.user ? children : <Login />}
    </>
)
}    
